<template>
  <div class="team-members-management-class">
    <div v-if="sources == 'DATW'" >
    <span>
      {{$translate('AefAutomate.EditTeamMembers.TitleDATWPART1')}}
    </span>
    <span  style="color: #00338D; cursor: pointer;text-decoration: underline;"  onClick="window.open(`https://digitalwebform.fr.kworld.kpmg.com/`, 'DATW', 'noopener');" >
      Digital Webform  </span>

    <span>
      {{$translate('AefAutomate.EditTeamMembers.TitleDATWPART2')}} 
    </span></div>
    <v-data-table :headers="headers" :loading="loading" :items="teamMembers" :hide-default-footer="true"
      disable-pagination>
      <template v-slot:header.FirstName="{ header }">
        {{ $translate(header.text) }}
      </template>
      <template v-slot:header.LastName="{ header }">
        {{ $translate(header.text) }}
      </template>
      <template v-slot:header.Email="{ header }">
        {{ $translate(header.text) }}
      </template>

      <template v-if="sources != 'DATW'" v-slot:item.action="{ item }">
        <v-btn small icon class="ma-2 team-delete" @click="deleteMember(item)">
          <v-icon color="primary">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div v-if="showForm">
      <v-row class="pa-3" align="center">
        <v-col cols="12" md="6">
          <v-autocomplete v-model="newMember" :items="items" :loading="isLoading" :search-input.sync="search"
            hide-no-data no-filter hide-details item-text="ContactFullName"
            :label="$translate('AefAutomate.EditTeamMembers.AddTeamMember')" outlined return-object></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn class="text-none" :disabled="newMember == null" type="button" tile outlined color="primary"
            @click="onClickButtonAddTeamMember()">
            <v-icon left>mdi-plus</v-icon>
            {{ $translate("AefAutomate.EditTeamMembers.AddValidation") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <a v-if="sources != 'DATW'" text tile class="color-primary-v2" @click="showForm = true">
        <v-icon class="add-icon mr-2"></v-icon>
        <span> {{ $translate("AefAutomate.EditTeamMembers.AddTeamMember") }}</span>
      </a>
    </div>

    <transition name="modal" v-if="loading">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document" style="max-width: 300px">
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import DelegationsService from "@services/delegations-service";
import RequestsService from "@services/requests-service";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
export default {
  mixins: [baseProps, localizationMixin],
  props: {
    requestId: String,
    sources: String
  },
  data() {
    return {
      delegationsService: null,
      requestsService: null,
      headers: [
        {
          text: "AefAutomate.EditTeamMembers.FirstName",
          value: "FirstName",
          align: "left",
        },
        {
          text: "AefAutomate.EditTeamMembers.LastName",
          value: "LastName",
          align: "left",
        },
        {
          text: "AefAutomate.EditTeamMembers.Email",
          value: "Email",
          align: "left",
        },
        { text: "", value: "action", align: "center" },
      ],
      teamMembers: [],
      items: [],
      loading: false,
      showForm: false,
      newMember: null,
      search: null,
      entries: [],
      isLoading: false,
    };
  },
  watch: {
    search(val) {
      if (val.length >= 4) val && this.querySelections(val);
    },
  },
  created() {
    this.delegationsService = new DelegationsService();
    this.requestsService = new RequestsService();

  },
  async mounted() {
    await this.getDelegations(this.requestId);
  },
  methods: {
    async deleteMember(item) {
      this.loading = true;
      await this.delegationsService.DeleteDelegation(item.Id).then(() => { /* TODO document why this arrow function is empty */ });

      await this.getDelegations(this.requestId);
    },
    async getDelegations(id) {
      this.loading = true;
      await this.delegationsService.FilterDelegations(id).then((response) => {
        this.teamMembers = response.data.values;
      });
      this.loading = false;
    },
    querySelections(v) {
      this.isLoading = true;

      this.requestsService.GetContacts(v, 30).then((response) => {
        this.items = response.data.values;
        this.isLoading = false;
      });
    },
    async onClickButtonAddTeamMember() {
      await this.delegationsService
        .CreateDelegation({
          RequestId: this.requestId,
          FirstName: this.newMember.ContactFirstName,
          LastName: this.newMember.ContactLastName,
          Email: this.newMember.ContactEmailOffice,
        })
        .then(() => {
          this.newMember = null;
          this.showForm = false;
        });

      await this.getDelegations(this.requestId);
    },
  },
};
</script>
<style lang="scss">
.team-members-management-class {
  .disable-inputs {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }

  .v-input__control {
    border-radius: 0px !important;
  }

  .v-input__slot {
    background-color: white !important;
  }
}
</style>