import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle Faqs
 */
export default class FaqsService extends AbstractService {

    constructor() {
        super();

        this.FaqsClient = super.createClient(configuration.AefAutomateApi.BaseUrl);
    }

    /**
     * Get Faqs 
     */
    GetFaqs() {
        return this.FaqsClient.get("Faqs/GetPdf")
            .then((response) => {
                return "data:application/pdf;base64," + response.data;
            });

    }


}
