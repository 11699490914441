<template>
  <v-app class="aef-report-view-class">
    <v-container style="height: 100%" fluid>
      <div v-if="showReport" class="showReportClass">
        <DisplayReport
          :baseUrlDshb="urlDshb"
          :baseUrlAutorisation="urlAutorisation"
          :token="token"
          report="AEF BI Automate/Rapport AEF BI Automate"
          :filters="FilterRequest"
        />
      </div>
    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable */
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import RequestsService from "@services/requests-service";


window["vuereport"] = window["vuereport"] || {};
export default {
  name: "AefViewReport",
  mixins: [baseProps, localizationMixin],
  props: {
    requestId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    requestsService: null,
    urlDshb: window.baseUrlDshb,
    urlAutorisation: window.baseUrlAutorisation,
    FilterRequest: null,
    showReport: false,
    accountNumber: null,
  }),
  methods: {
    getIbsFilterValue() {
      if (this.accountNumber != null) {
        this.FilterRequest =
          "AuditorSecurity:AccountNumber=" + this.accountNumber;
        this.ApplyFilter();
      }
    },
    ApplyFilter() {
      this.showReport = true;
    },
    async getRequest(requestId) {
      await this.requestsService.GetRequestsById(requestId).then((response) => {
        let request = JSON.parse(response.data);
        this.accountNumber = request.AccountNumber;
      });
    },
  },
  created() {
    this.requestsService = new RequestsService();

  },
  async mounted() {
    await this.getRequest(this.requestId);
    this.getIbsFilterValue();
  },
  components: {
    ...window["vuereport"].default,
  },
};
</script>

<style lang="scss">
.aef-report-view-class {
  height: 100%;
  background-color: #e6ebf4 !important;
  .column {
    max-width: 100%;
  }
  .showReportClass {
    height: 100%;
  }
}
</style>
