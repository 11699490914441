import english from '../i18n/english';
import french from '../i18n/french'

export const localizationMixin = {
    computed: {
        $translate: (app) => (path, params = {}) => {
            let localizedString = "";
            try {
                // Get localizations json corresponding to the current language
                const localizationsJson = app.$i18n.locale === "fr" ? french : english;
                const pathsArray = path.split(".");

                // Get the json propery given its path
                localizedString = pathsArray.reduce((prev, curr) => {
                    return prev[curr];
                }, localizationsJson)

                // Apply parameters if they exist
                if (params) {
                    Object.keys(params).forEach(function(key,index) {
                        console.log(index)
                        localizedString = localizedString.replace(`{${key}}`, params[key]);
                      });
                    
                }

                return localizedString
            }
            catch {
                return path;
            }
        },
    },
};
