import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle Delegations
 */
export default class DelegationsService extends AbstractService {

    constructor() {
        super();
        console.log("createClient")
        this.DelegationsClient = super.createClient(configuration.AefAutomateApi.BaseUrl);
    }

    /**
     * Get Delegations with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetDelegations(search, itemsPerPage, pageNumber, sortBy, sortDesc) {
        return this.DelegationsClient.get("v1/Delegations", {
            params: {
                "$top": itemsPerPage,
                "$skip": itemsPerPage * (pageNumber - 1),
                "$filter": `contains(IbsNumber,'${search}') 
                or contains(BusinessUnit,'${search}') 
                or contains(Name,'${search}') 
                or contains(Region,'${search}') 
                or contains(EngagementName,'${search}') 
                or contains(EngagementPartner,'${search}') 
                or contains(TeamContact,'${search}')`,
                "$orderBy": getOrderby(sortBy, sortDesc),
                "$count": true,
                "$expand": 'Team',
            }
        });
    }

    /**
    * Get Delegations with filters
    * @param {string} search
    */
    FilterDelegations(search) {
        let DelegationConfig = {
            params: {
                "$count": true
            }
        };

        if (search && search !== "") {
            DelegationConfig.params["$filter"] = `RequestId eq ${search}`
        }

        return this.DelegationsClient.get("v1/Delegations", DelegationConfig);
    }

    /**
    * Get Delegations by Request
    * @param {string} requestId
    */
    GetDelegationsByRequest(requestId) {
        let DelegationConfig = {
            params: {
                "$count": true,
            }
        };

        if (requestId && requestId !== "") {
            DelegationConfig.params["$filter"] = `RequestId eq ${requestId}`
        }

        return this.DelegationsClient.get("v1/Delegations", DelegationConfig);
    }

    /**
     * Delete Delegation by id
     * @param {string} id
     */
    DeleteDelegation(id) {
        return this.DelegationsClient.delete(`v1/Delegations(${id})`)
    }

    /**
     * Updates Delegation by id
     * @param {string} id
     * @param {any} DelegationBody
     */
    UpdateDelegation(id, DelegationBody) {
        return this.DelegationsClient.patch(`v1/Delegations(${id})`, DelegationBody)
    }

    /**
     * Create a Delegation
     * @param {any} DelegationBody
     */
    CreateDelegation(DelegationBody) {
        return this.DelegationsClient.post("v1/Delegations", DelegationBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy && sortBy[0]) {
        orderby = sortBy[0];
        if (sortDesc && sortDesc[0])
            orderby = orderby + " desc"
    }

    return orderby;
}