var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team-members-management-class"},[(_vm.sources == 'DATW')?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$translate('AefAutomate.EditTeamMembers.TitleDATWPART1'))+" ")]),_c('span',{staticStyle:{"color":"#00338D","cursor":"pointer","text-decoration":"underline"},attrs:{"onClick":"window.open(`https://digitalwebform.fr.kworld.kpmg.com/`, 'DATW', 'noopener');"}},[_vm._v(" Digital Webform ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$translate('AefAutomate.EditTeamMembers.TitleDATWPART2'))+" ")])]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.teamMembers,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"header.FirstName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$translate(header.text))+" ")]}},{key:"header.LastName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$translate(header.text))+" ")]}},{key:"header.Email",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$translate(header.text))+" ")]}},(_vm.sources != 'DATW')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2 team-delete",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteMember(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-trash-can-outline")])],1)]}}:null],null,true)}),(_vm.showForm)?_c('div',[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"hide-no-data":"","no-filter":"","hide-details":"","item-text":"ContactFullName","label":_vm.$translate('AefAutomate.EditTeamMembers.AddTeamMember'),"outlined":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.newMember),callback:function ($$v) {_vm.newMember=$$v},expression:"newMember"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"text-none",attrs:{"disabled":_vm.newMember == null,"type":"button","tile":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.onClickButtonAddTeamMember()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.EditTeamMembers.AddValidation"))+" ")],1)],1)],1)],1):_c('div',[(_vm.sources != 'DATW')?_c('a',{staticClass:"color-primary-v2",attrs:{"text":"","tile":""},on:{"click":function($event){_vm.showForm = true}}},[_c('v-icon',{staticClass:"add-icon mr-2"}),_c('span',[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.EditTeamMembers.AddTeamMember")))])],1):_vm._e()]),(_vm.loading)?_c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-dialog",staticStyle:{"max-width":"300px"},attrs:{"role":"document"}},[_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }