import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle headers
 */
export default class HeadersService extends AbstractService {

    constructor() {
        super();

        this.headersClient = super.createClient(configuration.AefAutomateApi.BaseUrl);
    }

    /**
     * Get headers with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetHeaders(search, itemsPerPage, pageNumber, sortBy, sortDesc) {
        return this.headersClient.get("v1/Headers", {
            params: {
                "$top": itemsPerPage,
                "$skip": itemsPerPage * (pageNumber - 1),
                "$filter": `contains(IbsNumber,'${search}') 
                or contains(BusinessUnit,'${search}') 
                or contains(Name,'${search}') 
                or contains(Region,'${search}') 
                or contains(EngagementName,'${search}') 
                or contains(EngagementPartner,'${search}') 
                or contains(TeamContact,'${search}')`,
                "$orderBy": getOrderby(sortBy, sortDesc),
                "$count": true,
                "$expand": 'Team',
            }
        });
    }

    /**
    * Get headers with filters
    * @param {string} search
    */
    FilterHeaders(search) {
        let headerConfig = {
            params: {
                "$top": 100,
                "$count": true,
                "$expand": 'Team',
            }
        };

        if (search && search !== "") {
            headerConfig.params["$filter"] = `contains(IbsNumber,'${search}') 
           or contains(BusinessUnit,'${search}') 
           or contains(Name,'${search}') 
           or contains(Region,'${search}') 
           or contains(EngagementName,'${search}') 
           or contains(EngagementPartner,'${search}') 
           or contains(TeamContact,'${search}')`
        }

        return this.headersClient.get("Headers", headerConfig);
    }

    /**
    * Get headers by Request
    * @param {string} requestId
    */
    GetHeadersByRequest(requestId) {
        let headerConfig = {
            params: {
                "$count": true,
            }
        };

        if (requestId && requestId !== "") {
            headerConfig.params["$filter"] = `RequestId eq ${requestId}`
        }

        return this.headersClient.get("v1/Headers", headerConfig);
    }

    /**
     * Delete header by id
     * @param {string} id
     */
    DeleteHeader(id) {
        return this.headersClient.delete(`v1/Headers(${id})`)
    }

    /**
     * Updates header by id
     * @param {string} id
     * @param {any} headerBody
     */
    UpdateHeader(id, headerBody) {
        return this.headersClient.patch(`v1/Headers(${id})`, headerBody)
    }

    /**
     * Create a header
     * @param {any} headerBody
     */
    CreateHeader(headerBody) {
        return this.headersClient.post("v1/Headers", headerBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy && sortBy[0]) {
        orderby = sortBy[0];
        if (sortDesc && sortDesc[0])
            orderby = orderby + " desc"
    }

    return orderby;
}