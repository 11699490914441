import axios from "axios";

export default class AbstractService {
    getAccessToken() {
        return localStorage.getItem(`adal.idtoken`)
    }

    createClient(apiConfigObject) {
        let instance = axios.create({
            transformResponse: [function (json) {
                if (json) {
                    try {
                        let data = JSON.parse(json);
                        if (data['@odata.count'] != undefined || data.value != undefined) {
                            return {
                                count: data['@odata.count'],
                                values: data.value
                            };
                        }
                    }
                    catch {
                        // Nothing to do, the response is not a json
                    }
                    return json;
                }
            }],
            baseURL: apiConfigObject.version ? `${apiConfigObject.url}/${apiConfigObject.version}` : apiConfigObject.url

        });
        let self = this

        // Add a request interceptor
        instance.interceptors.request.use(function (config) {
            const token = `Bearer ${self.getAccessToken()}`;
            config.headers.Authorization = token;
            return config;
        });

        instance.interceptors.response.use(async function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // Use the 202 response code as an indicator that polling is needed
            
            if (response.status === 202) {

                // Retrieve the initial operation status
                let pollingResponse = await new Promise((resolve, reject) => {
                    
                    axios.get(response.headers.location, {
                        headers: {
                            'Authorization': `Bearer ${self.getAccessToken()}`
                        }
                    }).then(res => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    })
                });
                

                // Loop while the operation is still in progress...
                while (pollingResponse.status == 202) {
                    await new Promise((resolve) => setTimeout(() => {
                        resolve();
                    }, 2000));

                    pollingResponse = await new Promise((resolve, reject) => {
                        axios.get(response.headers.location, {
                            headers: {
                                'Authorization': `Bearer ${self.getAccessToken()}`
                            }
                        }).then((res) => {
                            resolve(res);
                        }).catch((error) => {
                            reject(error);
                        })
                    });
                }
            }
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });

        return instance
    }
}