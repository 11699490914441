<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Error-Dialog-Component :open="openErrorDialog"></Error-Dialog-Component>

    <v-row class="mapping-component-class mt-3">
      <v-col cols="12" md="12">
        <v-card height="100%">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <div class="mr-3 title primary--text font-weight-bold">
                    {{ $translate("AefAutomate.Stepper.Mapping.Title") }}
                  </div>
                  <div class="primary--text">
                    {{ $translate("AefAutomate.Stepper.Mapping.SubTitle") }}
                    <span class="red--text">*</span>
                  </div>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="py-0" cols="12" md="10">
                    <v-row no-gutters align="end">
                      <p class="primary--text title">Base N</p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Numéro de compte -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Numéro de compte N" rules="required">
                      <v-autocomplete v-model="numeroCompteN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="numeroCompteN = null" return-object required>
                        <template v-slot:label>
                          Numéro de compte <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Libellé de compte -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Libellé de compte N" rules="required">
                      <v-autocomplete v-model="libelleCompteN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="libelleCompteN = null" return-object required>
                        <template v-slot:label>
                          Libellé de compte <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Numéro d'écriture -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Numéro d'écriture N" rules="required">
                      <v-autocomplete v-model="numeroEcritureN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="numeroEcritureN = null" return-object required>
                        <template v-slot:label>
                          Numéro d'écriture <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Libellé d'écriture -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Libellé d'écriture N" rules="required">
                      <v-autocomplete v-model="libelleEcritureN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="libelleEcritureN = null" return-object required>
                        <template v-slot:label>
                          Libellé d'écriture <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Code Journal -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Code journal N" rules="required">
                      <v-autocomplete v-model="codeJournalN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="codeJournalN = null" return-object required>
                        <template v-slot:label>
                          Code journal <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Date comptable -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Date comptable N" rules="required">
                      <v-autocomplete v-model="dateComptableN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="dateComptableN = null" return-object required>
                        <template v-slot:label>
                          Date comptable <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Lettrage -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="lettrageN" :items="headersN" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Lettrage" outlined color="primary" clearable
                      @click:clear="lettrageN = null" return-object>
                    </v-autocomplete>
                  </v-col>
                  <!-- Compte auxiliaire -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="compteAuxiliaireN" :items="headersN" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Compte auxiliaire" outlined color="primary"
                      clearable @click:clear="compteAuxiliaireN = null" return-object>
                    </v-autocomplete>
                  </v-col>
                  <!-- Code établissement  -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="codeEtablissementN" :items="headersN" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Code établissement" outlined color="primary"
                      clearable @click:clear="codeEtablissementN = null" return-object>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="py-0" cols="12" md="10">
                    <v-row no-gutters align="end">
                      <p class="primary--text title">Base N-1</p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Numéro de compte -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Numéro de compte N_1" rules="required">
                      <v-autocomplete v-model="numeroCompteN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="numeroCompteN_1 = null" return-object required>
                        <template v-slot:label>
                          Numéro de compte <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Libellé de compte -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Libellé de compte N_1" rules="required">
                      <v-autocomplete v-model="libelleCompteN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="libelleCompteN_1 = null" return-object required>
                        <template v-slot:label>
                          Libellé de compte <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Numéro d'écriture -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Numéro d'écriture N_1" rules="required">
                      <v-autocomplete v-model="numeroEcritureN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="numeroEcritureN_1 = null" return-object required>
                        <template v-slot:label>
                          Numéro d'écriture <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Libellé d'écriture -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Libellé d'écriture N_1" rules="required">
                      <v-autocomplete v-model="libelleEcritureN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="libelleEcritureN_1 = null" return-object required>
                        <template v-slot:label>
                          Libellé d'écriture <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Code journal -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Code journal N_1" rules="required">
                      <v-autocomplete v-model="codeJournalN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="codeJournalN_1 = null" return-object required>
                        <template v-slot:label>
                          Code journal <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Date comptable -->
                  <v-col class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Date comptable N_1" rules="required">
                      <v-autocomplete v-model="dateComptableN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="dateComptableN_1 = null" return-object required>
                        <template v-slot:label>
                          Date comptable <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Lettrage -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="lettrageN_1" :items="headersN_1" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Lettrage" outlined color="primary" clearable
                      @click:clear="lettrageN_1 = null" return-object>
                    </v-autocomplete>
                  </v-col>
                  <!-- Compte auxiliaire -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="compteAuxiliaireN_1" :items="headersN_1" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Compte auxiliaire" outlined color="primary"
                      clearable @click:clear="compteAuxiliaireN_1 = null" return-object>
                    </v-autocomplete>
                  </v-col>
                  <!-- Code établissement  -->
                  <v-col class="py-0" cols="12" lg="5">
                    <v-autocomplete v-model="codeEtablissementN_1" :items="headersN_1" item-disabled="Disabled"
                      item-text="ColumnName" item-value="ColumnName" label="Code établissement" outlined color="primary"
                      clearable @click:clear="codeEtablissementN_1 = null" return-object>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="py-0" cols="12" md="10">
                    <v-row no-gutters align="end">
                      <p class="primary--text title">
                        {{
                            $translate(
                              "AefAutomate.Stepper.Mapping.SoldeComptableQuestion"
                            )
                        }}

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mx-1" v-on="on" color="primary">
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text1"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text2"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text3"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text4"
                                  )
                              }}
                            </div>
                          </div>
                        </v-tooltip>
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <validation-provider v-slot="{ errors }" name="Type de mapping N" rules="required">
                    <v-radio-group v-model="soldeComptableN" :error-messages="errors" column>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse1'
                        )
                      " :value="0"></v-radio>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse2'
                        )
                      " :value="1"></v-radio>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse3'
                        )
                      " :value="2"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="py-0" cols="12" md="10">
                    <v-row no-gutters align="end">
                      <p class="primary--text title">
                        {{
                            $translate(
                              "AefAutomate.Stepper.Mapping.SoldeComptableQuestion"
                            )
                        }}

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mx-1" v-on="on" color="primary">
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text1"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text2"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text3"
                                  )
                              }}
                            </div>
                            <div>
                              {{
                                  $translate(
                                    "AefAutomate.Stepper.Mapping.Tooltip.Text4"
                                  )
                              }}
                            </div>
                          </div>
                        </v-tooltip>
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <validation-provider v-slot="{ errors }" name="Type de mapping N_1" rules="required">
                    <v-radio-group v-model="soldeComptableN_1" :error-messages="errors" column>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse1'
                        )
                      " :value="0"></v-radio>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse2'
                        )
                      " :value="1"></v-radio>
                      <v-radio :label="
                        $translate(
                          'AefAutomate.Stepper.Mapping.SoldeComptableResponse3'
                        )
                      " :value="2"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <!-- Débit -->
                  <v-col v-if="soldeComptableN == 0" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Débit N" rules="required">
                      <v-autocomplete v-model="debitN" :items="headersN" item-disabled="Disabled" item-text="ColumnName"
                        item-value="ColumnName" utlined color="primary" :error-messages="errors" clearable outlined
                        @click:clear="debitN = null" return-object required>
                        <template v-slot:label>
                          Débit <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Crédit -->
                  <v-col v-if="soldeComptableN == 0" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Crédit N" rules="required">
                      <v-autocomplete v-model="creditN" :items="headersN" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="creditN = null" return-object required>
                        <template v-slot:label>
                          Crédit <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Solde -->
                  <v-col v-if="soldeComptableN == 1 || soldeComptableN == 2" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Solde N" rules="required">
                      <v-autocomplete v-model="soldeN" :items="headersN" item-disabled="Disabled" item-text="ColumnName"
                        item-value="ColumnName" outlined color="primary" :error-messages="errors" clearable
                        @click:clear="soldeN = null" return-object required>
                        <template v-slot:label>
                          Solde <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Type -->
                  <v-col v-if="soldeComptableN == 2" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Type N" rules="required">
                      <v-autocomplete v-model="typeN" :items="headersN" item-disabled="Disabled" item-text="ColumnName"
                        item-value="ColumnName" outlined color="primary" :error-messages="errors" clearable
                        @click:clear="typeN = null" return-object required>
                        <template v-slot:label>
                          Type <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <!-- Débit -->
                  <v-col v-if="soldeComptableN_1 == 0" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Débit N_1" rules="required">
                      <v-autocomplete v-model="debitN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="debitN_1 = null" return-object required>
                        <template v-slot:label>
                          Débit <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Crédit -->
                  <v-col v-if="soldeComptableN_1 == 0" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Crédit N_1" rules="required">
                      <v-autocomplete v-model="creditN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="creditN_1 = null" return-object required>
                        <template v-slot:label>
                          Crédit <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Solde -->
                  <v-col v-if="soldeComptableN_1 == 1 || soldeComptableN_1 == 2" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Solde N_1" rules="required">
                      <v-autocomplete v-model="soldeN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="soldeN_1 = null" return-object required>
                        <template v-slot:label>
                          Solde <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <!-- Type -->
                  <v-col v-if="soldeComptableN_1 == 2" class="py-0" cols="12" lg="5">
                    <validation-provider v-slot="{ errors }" name="Type N_1" rules="required">
                      <v-autocomplete v-model="typeN_1" :items="headersN_1" item-disabled="Disabled"
                        item-text="ColumnName" item-value="ColumnName" outlined color="primary" :error-messages="errors"
                        clearable @click:clear="typeN_1 = null" return-object required>
                        <template v-slot:label>
                          Type <span class="red--text">*</span>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="hideInputs" class="disable-inputs">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-card width="500">
                <v-row class="pa-3" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    {{ $translate("AefAutomate.Stepper.Mapping.LoadingData") }}
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-row justify="center">
          <v-btn :disabled="invalid || hideInputs" class="ma-2 text-none" color="primary" @click="nextStep">
            <v-icon :disabled="hideInputs" left dark>mdi-arrow-right</v-icon>
            {{
                hideInputs
                  ? "Chargement en cours"
                  : "Enregistrer et passer à l’étape suivante"
            }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </validation-observer>
</template>
<script>
import ErrorDialogComponent from "./ErrorDialogComponent";
import MappingsService from "@services/mappings-service";
import HeadersService from "@services/headers-service";
import RequestsService from "@services/requests-service";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";

export default {
  mixins: [baseProps, localizationMixin],
  props: {
    requestProp: Object,
  },
  components: {
    ErrorDialogComponent,
  },
  data() {
    return {
      mappingsService: null,
      headersService: null,
      requestsService: null,
      headers: [],
      request: {},
      valid: false,
      codeJournalN: null,
      creditN: null,
      dateComptableN: null,
      debitN: null,
      libelleCompteN: null,
      libelleEcritureN: null,
      numeroCompteN: null,
      numeroEcritureN: null,
      soldeN: null,
      typeN: null,
      codeJournalN_1: null,
      creditN_1: null,
      dateComptableN_1: null,
      debitN_1: null,
      libelleCompteN_1: null,
      libelleEcritureN_1: null,
      numeroCompteN_1: null,
      numeroEcritureN_1: null,
      lettrageN: null,
      lettrageN_1: null,
      compteAuxiliaireN_1: null,
      compteAuxiliaireN: null,
      codeEtablissementN: null,
      codeEtablissementN_1: null,
      soldeN_1: null,
      typeN_1: null,
      mapping: [],
      timerId: null,
      hideInputs: false,
      openErrorDialog: false,
      soldeComptableN: null,
      soldeComptableN_1: null,
      compteNumPattern: ["compte general", "numero de compte", "comptenum", "acc number", "comptegen"],
      compteLibPattern: ["designation compte general", "libelle de compte", "comptelib", "designation de compte", "acc short text"],
      ecritureDatePattern: ["date comptable", "pstng date", "ecrituredate"],
      journalCodePattern: ["code journal", "journalcode", "journal code", "jl", "jal"],
      ecritureNumPattern: ["ecriture", "ecriturenum", "n° ecriture", "documentno", "numero d'ecriture", "numero ecriture"],
      ecritureLibPattern: ["libelle ecriture", "libelle d'ecriture", "ecriturelib"],
      debitPattern: ["debit", "deb."],
      creditPattern: ["credit", "cred."],
      soldePattern: ["solde", "solde cumule", "lc amount", "montant"],
      typePattern: ["type", "sens", "d/C", "debit/credit", "d / c"],
      compAuxLibPattern: ["libelle compte aux", "libelle de compte auxiliaire", "compauxlib", "compauxnum", "numero de compte auxiliaire", "numero compte aux"],
      ecritureLetPattern: ["lettrage", "lettr.", "clearing", "clrng doc.", "ecriturelet"],
      codeEtbtPattern: ["codeetbt", "codeetbts", "etablissement", "entite", "numero entite", "code etablissement"]
    };
  },
  watch: {
    libelleCompteN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName) {
          element["Disabled"] = false;
        }

        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    numeroCompteN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    codeJournalN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    creditN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    dateComptableN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    debitN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    libelleEcritureN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    numeroEcritureN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    soldeN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    typeN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    compteAuxiliaireN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    lettrageN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    codeEtablissementN: function (newVal, oldVal) {
      // watch it
      this.headersN.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    codeJournalN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    creditN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach(element => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    dateComptableN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    debitN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    libelleCompteN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    libelleEcritureN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    numeroCompteN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    numeroEcritureN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    soldeN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    typeN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    compteAuxiliaireN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    lettrageN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    codeEtablissementN_1: function (newVal, oldVal) {
      // watch it
      this.headersN_1.forEach((element) => {
        if (oldVal != null && element.ColumnName == oldVal.ColumnName)
          element["Disabled"] = false;
        if (newVal != null && element.ColumnName == newVal.ColumnName)
          element["Disabled"] = true;
      });
    },
    headersN(value) {
      let codeJournalN = value.filter((h) => this.journalCodePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.codeJournalN = this.getValue(
        codeJournalN.length == 1 ? codeJournalN[0] : ""
      );
      let creditN = value.filter((h) => this.creditPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.creditN = this.getValue(
        creditN.length == 1 ? creditN[0] : ""
      );
      let dateComptableN = value.filter((h) => this.ecritureDatePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.dateComptableN = this.getValue(
        dateComptableN.length == 1 ? dateComptableN[0] : ""
      );
      let debitN = value.filter((h) => this.debitPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.debitN = this.getValue(
        debitN.length == 1 ? debitN[0] : ""
      );
      let libelleCompteN = value.filter((h) => this.compteLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.libelleCompteN = this.getValue(
        libelleCompteN.length == 1 ? libelleCompteN[0] : ""
      );
      let libelleEcritureN = value.filter((h) => this.ecritureLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.libelleEcritureN = this.getValue(
        libelleEcritureN.length == 1 ? libelleEcritureN[0] : ""
      );
      let numeroCompteN = value.filter((h) => this.compteNumPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.numeroCompteN = this.getValue(
        numeroCompteN.length == 1 ? numeroCompteN[0] : ""
      );
      let numeroEcritureN = value.filter((h) => this.ecritureNumPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.numeroEcritureN = this.getValue(
        numeroEcritureN.length == 1 ? numeroEcritureN[0] : ""
      );
      let soldeN = value.filter((h) => this.soldePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.soldeN = this.getValue(
        soldeN.length == 1 ? soldeN[0] : ""
      );
      let typeN = value.filter((h) => this.typePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.typeN = this.getValue(
        typeN.length == 1 ? typeN[0] : ""
      );
      let compteAuxiliaireN = value.filter((h) => this.compAuxLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.compteAuxiliaireN = this.getValue(
        compteAuxiliaireN.length == 1 ? compteAuxiliaireN[0] : ""
      );
      let lettrageN = value.filter((h) => this.ecritureLetPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')));
      this.lettrageN = this.getValue(
        lettrageN.length == 1 ? lettrageN[0] : ""
      );
      let codeEtablissementN = value.filter((h) => this.codeEtbtPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.codeEtablissementN = this.getValue(
        codeEtablissementN.length == 1 ? codeEtablissementN[0] : ""
      );
    },
    headersN_1(value) {
      let codeJournalN_1 = value.filter((h) => this.journalCodePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.codeJournalN_1 = this.getValue(
        codeJournalN_1.length == 1 ? codeJournalN_1[0] : ""
      );
      let creditN_1 = value.filter((h) => this.creditPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.creditN_1 = this.getValue(
        creditN_1.length == 1 ? creditN_1[0] : ""
      );
      let dateComptableN_1 = value.filter((h) => this.ecritureDatePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.dateComptableN_1 = this.getValue(
        dateComptableN_1.length == 1 ? dateComptableN_1[0] : ""
      );
      let debitN_1 = value.filter((h) => this.debitPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.debitN_1 = this.getValue(
        debitN_1.length == 1 ? debitN_1[0] : ""
      );
      let libelleCompteN_1 = value.filter((h) => this.compteLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.libelleCompteN_1 = this.getValue(
        libelleCompteN_1.length == 1 ? libelleCompteN_1[0] : ""
      );
      let libelleEcritureN_1 = value.filter((h) => this.ecritureLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.libelleEcritureN_1 = this.getValue(
        libelleEcritureN_1.length == 1 ? libelleEcritureN_1[0] : ""
      );
      let numeroCompteN_1 = value.filter((h) => this.compteNumPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.numeroCompteN_1 = this.getValue(
        numeroCompteN_1.length == 1 ? numeroCompteN_1[0] : ""
      );
      let numeroEcritureN_1 = value.filter((h) => this.ecritureNumPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.numeroEcritureN_1 = this.getValue(
        numeroEcritureN_1.length == 1 ? numeroEcritureN_1[0] : ""
      );
      let soldeN_1 = value.filter((h) => this.soldePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.soldeN_1 = this.getValue(
        soldeN_1.length == 1 ? soldeN_1[0] : ""
      );
      let typeN_1 = value.filter((h) => this.typePattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.typeN_1 = this.getValue(
        typeN_1.length == 1 ? typeN_1[0] : ""
      );
      let compteAuxiliaireN_1 = value.filter((h) => this.compAuxLibPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.compteAuxiliaireN_1 = this.getValue(
        compteAuxiliaireN_1.length == 1 ? compteAuxiliaireN_1[0] : ""
      );
      let lettrageN_1 = value.filter((h) => this.ecritureLetPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.lettrageN_1 = this.getValue(
        lettrageN_1.length == 1 ? lettrageN_1[0] : ""
      );
      let codeEtablissementN_1 = value.filter((h) => this.codeEtbtPattern.includes(h.ColumnName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      this.codeEtablissementN_1 = this.getValue(
        codeEtablissementN_1.length == 1 ? codeEtablissementN_1[0] : ""
      );
    },
  },
  computed: {
    headersN() {
      if (this.headers != null)
        return this.headers.filter((h) => h.FileName == "BaseN");
      return [];
    },
    headersN_1() {
      if (this.headers != null)
        return this.headers.filter((h) => h.FileName == "BaseN_1");
      return [];
    },
  },
  created() {
    this.mappingsService = new MappingsService();
    this.headersService = new HeadersService();
    this.requestsService = new RequestsService();
  },
  async mounted() {
    this.request = this.requestProp;
    if (this.request.State == 2) {
      await this.getRequest(this.request.Id);
    }
    await this.getHeaders();
  },
  beforeDestroy() {
    if (this.timerId != null) clearInterval(this.timerId);
  },
  methods: {
    async getRequest(id) {
      let self = this;
      this.hideInputs = true;
      await this.requestsService.GetRequestsById(id).then((response) => {
        this.request = JSON.parse(response.data);
      });

      if (this.request.State == 2) {
        self.timerId = setInterval(async () => {
          self.hideInputs = true;
          await this.requestsService.GetRequestsById(id).then((response) => {
            this.request = JSON.parse(response.data);
            if (this.request.State == 9) {
              self.openErrorDialog = true;
            }
            if (this.request.State == 3) {
              self.hideInputs = false;
              clearInterval(self.timerId);
              self.timerId = null;
              self.$emit("NextStep", this.request);
            }
          });
        }, 20000);
      } else {
        this.hideInputs = false;
        if (this.request.State == 3) {
          self.$emit("NextStep", this.request);
        }
      }
    },
    async getHeaders() {
      await this.headersService
        .GetHeadersByRequest(this.request.Id)
        .then((response) => {
          this.headers = response.data.values;
        });
    },
    contains(column, pattern) {
      return pattern.filter((e) => e.ColumnName.trim().toLowerCase() === column.trim().toLowerCase());
    },
    getValue(variable) {
      if (
        typeof variable === "object" &&
        variable !== null &&
        variable !== undefined
      ) {
        variable.Disabled = true;
        return variable;
      }

      return null;
    },
    async nextStep() {
      this.hideInputs = true;
      //Mapping N
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "CodeJournal",
        ColumnSource: this.codeJournalN.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.creditN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "Credit",
          ColumnSource: this.creditN.ColumnName,
          RequestId: this.request.Id,
        });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "DateComptable",
        ColumnSource: this.dateComptableN.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.debitN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "Debit",
          ColumnSource: this.debitN.ColumnName,
          RequestId: this.request.Id,
        });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "CompteLibelle",
        ColumnSource: this.libelleCompteN.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "EcritureLibelle",
        ColumnSource: this.libelleEcritureN.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "Compte",
        ColumnSource: this.numeroCompteN.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN[0].FileName,
        FileType: this.headersN[0].FileType,
        Separator: this.headersN[0].Separator,
        ColumnSink: "EcritureNumero",
        ColumnSource: this.numeroEcritureN.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.soldeN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "Solde",
          ColumnSource: this.soldeN.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.typeN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "TypeN",
          ColumnSource: this.typeN.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.compteAuxiliaireN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "CompAuxLib",
          ColumnSource: this.compteAuxiliaireN.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.lettrageN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "EcritureLet",
          ColumnSource: this.lettrageN.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.codeEtablissementN != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN[0].FileName,
          FileType: this.headersN[0].FileType,
          Separator: this.headersN[0].Separator,
          ColumnSink: "CodeEtbt",
          ColumnSource: this.codeEtablissementN.ColumnName,
          RequestId: this.request.Id,
        });

      //Mapping N-1
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "CodeJournal",
        ColumnSource: this.codeJournalN_1.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.creditN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "Credit",
          ColumnSource: this.creditN_1.ColumnName,
          RequestId: this.request.Id,
        });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "DateComptable",
        ColumnSource: this.dateComptableN_1.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.debitN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "Debit",
          ColumnSource: this.debitN_1.ColumnName,
          RequestId: this.request.Id,
        });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "CompteLibelle",
        ColumnSource: this.libelleCompteN_1.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "EcritureLibelle",
        ColumnSource: this.libelleEcritureN_1.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "Compte",
        ColumnSource: this.numeroCompteN_1.ColumnName,
        RequestId: this.request.Id,
      });
      this.mapping.push({
        FolderName: this.request.Id,
        FileName: this.headersN_1[0].FileName,
        FileType: this.headersN_1[0].FileType,
        Separator: this.headersN_1[0].Separator,
        ColumnSink: "EcritureNumero",
        ColumnSource: this.numeroEcritureN_1.ColumnName,
        RequestId: this.request.Id,
      });

      if (this.soldeN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "Solde",
          ColumnSource: this.soldeN_1.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.typeN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "TypeN_1",
          ColumnSource: this.typeN_1.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.compteAuxiliaireN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "CompAuxLib",
          ColumnSource: this.compteAuxiliaireN_1.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.lettrageN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "EcritureLet",
          ColumnSource: this.lettrageN_1.ColumnName,
          RequestId: this.request.Id,
        });

      if (this.codeEtablissementN_1 != null)
        this.mapping.push({
          FolderName: this.request.Id,
          FileName: this.headersN_1[0].FileName,
          FileType: this.headersN_1[0].FileType,
          Separator: this.headersN_1[0].Separator,
          ColumnSink: "CodeEtbt",
          ColumnSource: this.codeEtablissementN_1.ColumnName,
          RequestId: this.request.Id,
        });

      this.request.SoldeComptableN = this.soldeComptableN;
      this.request.SoldeComptableN_1 = this.soldeComptableN_1;
      await this.requestsService
        .UpdateRequest(this.request.Id, this.request)
        .then(() => { /* TODO document why this arrow function is empty */  });

      await this.mappingsService.CreateMapping(this.mapping).then(() => { /* TODO document why this arrow function is empty */  });

      await this.getRequest(this.request.Id);
    },
  },
};
</script>
<style lang="scss">
.mapping-component-class {
  .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    font-weight: 600;
  }

  a:hover {
    color: #0056b3;
    text-decoration: none !important;
  }

  .disable-inputs {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }

  .v-input__control {
    border-radius: 0px !important;
  }

  .v-input__slot {
    background-color: white !important;
  }

  .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-weight: 600;
  }

  .v-input--selection-controls .v-input__slot>.v-label,
  .v-input--selection-controls .v-radio>.v-label {
    color: #00338d;
  }
}
</style>