import configuration from "../config/configuration";
import AbstractService from "./abstract-service";
import { FileUploader } from '../helpers/fileUploader'
import {
  BlockBlobClient,
  AnonymousCredential,
  newPipeline,
  BaseRequestPolicy
} from "@azure/storage-blob";
/**
 * Service used to handle publication requests
 */
class SasStore {
  constructor() {
    this.sasCache = {};
  }

  // Get a valid SAS for blob
  async getValidSASForBlob(blobURL) {
    if (this.sasCache[blobURL] && this.isSasStillValidInNext2Mins(this.sasCache[blobURL])) {
      return this.sasCache[blobURL];
    } else {
      this.sasCache[blobURL] = await this.getNewSasForBlob(blobURL)
      return this.sasCache[blobURL] ;
    }
  }

  // Return true if "se" section in SAS is still valid in next 2 mins
  isSasStillValidInNext2Mins(sas) {
    const expiryStringInSas = new URL(`http://hostname${sas}`).searchParams.get("se");
    return new Date(expiryStringInSas) - new Date() >= 2 * 60 * 1000;
  }

  // Get a new SAS for blob, we assume a SAS starts with a "?"
  async getNewSasForBlob(blobURL) {
    // TODO: You need to implement this
    return blobURL + "?newSAS";
  }
}

class SasUpdatePolicyFactory {
  constructor(sasStore) {
    this.sasStore = sasStore;
  }
  create(nextPolicy, options) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}

class SasUpdatePolicy extends BaseRequestPolicy {
  constructor(nextPolicy, options, sasStore) {
    super(nextPolicy, options);
    this.sasStore = sasStore;
  }

  async sendRequest(request) {
    const urlObj = new URL(request.url);
    const sas = await this.sasStore.getValidSASForBlob(`${urlObj.origin}${urlObj.pathname}`);
    new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
    });

    // Update request URL with latest SAS
    request.url = urlObj.toString();

    return this._nextPolicy.sendRequest(request);
  }
}

export default class UploadService extends AbstractService {
  constructor() {
    super();

    this.aefAutomate = super.createClient(configuration.AefAutomateApi.BaseUrl);
  }

  /**
   * Upload Document
   * @param {any} file
   * @param {string} folderName
   * @param {string} delimiter
   * @param {function} onUploadProgress
   */
  UploadDocument(file, folderName, delimiter, fileName, onUploadProgress) {
    let formData = new FormData();
    formData.append("formFile", file);
    formData.append("folderName", folderName);
    formData.append("delimiter", delimiter);
    formData.append("fileNameDest", fileName);
    return this.aefAutomate.post("UploadDocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  /**
   * Upload Document With Headers
   * @param {any} file
   * @param {string} folderName
   * @param {string} delimiter
   * @param {function} onUploadProgress
   */
  UploadDocumentWithHeaders(file, folderName, delimiter, fileName, onUploadProgress) {
    let formData = new FormData();
    formData.append("formFile", file);
    formData.append("folderName", folderName);
    formData.append("delimiter", delimiter);
    formData.append("fileNameDest", fileName);
    return this.aefAutomate.post("UploadDocumentWithHeaders", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
  /**
   * Upload Document To Storage Account
   * @param {any} file
   * @param {string} url
   * @param {function} onUploadProgress
   */
  async UploadToStorageAccount(file, url, onUploadProgress) {
    const sasStore = new SasStore();

    const pipeline = newPipeline(new AnonymousCredential());
    // Inject SAS update policy factory into current pipeline
    pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));

    const blockBlobClient = new BlockBlobClient(
      //`${url}${await sasStore.getValidSASForBlob(url)}`, // A SAS should start with "?"
      url,
      pipeline
    );

    await blockBlobClient.uploadData(file, {
      maxSingleShotSize: 4 * 1024 * 1024,
      blobHTTPHeaders: { blobContentType: file.type }, // set mimetype,
      onProgress: onUploadProgress
    });
  }
  /**
   * Get SAS URI
   * @param {string} folderName
   * @param {string} fileName
   * */
  GetSasUri(folderName, fileName) {
    let params = {
      "folderName": folderName,
      "fileName": fileName
    }
    return this.aefAutomate.get("UploadFile/GetSasUri", {
      params: params
    })
  }
  /**
   * Get Headers
   * @param {string} folderName
   * @param {string} fileName
   * @param {string} delimiter
   */
  GetHeaders(folderName, fileName, delimiter, encoding) {
    let params = {
      "folderName": folderName,
      "fileName": fileName,
      "delimiter": delimiter,
      "encoding": encoding
    }
    return this.aefAutomate.get("UploadFile/GetHeaders", {
      params: params
    });
  }
  /**
   * Get Headers Process Status
   * @param {string} processId
   */
  GetHeadersProcessStatus(processId) {
    return this.aefAutomate.get("UploadFile/Status/" + processId);
  }

  async UploadChunked(requestId, file, fileName, progress) {
    const uploadChunkUrl = `UploadFile/UploadChunk?requestId=${requestId}`
    const commitUrl = `UploadFile/UploadFile?requestId=${requestId}&fileName=${fileName}`

      try {
        let fileUploader = FileUploader(file, { fileId: fileName, requestId }, this.aefAutomate, uploadChunkUrl, commitUrl, 25857600)
        fileUploader.reportProgress(progress);
        await fileUploader.upload();
      } catch (e) {
        console.log(e)
      }
  }
}
