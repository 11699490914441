<template>
  <v-app class="aef-import-view-class">
    <v-container fluid>
      <Error-Dialog-Component :open="openErrorDialog"></Error-Dialog-Component>
      <p class="title primary--text font-weight-bold">
        {{ $translate("AefAutomate.Stepper.Title") }}
      </p>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            {{ $translate("AefAutomate.Stepper.UploadFiles.Title") }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            {{ $translate("AefAutomate.Stepper.Mapping.Title") }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3">
            {{ $translate("AefAutomate.Stepper.Settings.Title") }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <div>
        <div v-if="e1 == 1">
          <files-selection-component @NextStep="setRequest" />
        </div>

        <div v-if="e1 == 2">
          <mapping-component
            v-if="e1 == 2"
            :requestProp="request"
            @NextStep="setRequest"
          />
        </div>

        <div v-if="e1 == 3">
          <settings-component
            v-if="e1 == 3"
            :requestProp="request"
            @NextStep="setRequest"
          />
        </div>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import FilesSelectionComponent from "./stepperComponents/FilesSelectionComponent";
import ErrorDialogComponent from "./stepperComponents/ErrorDialogComponent";
import MappingComponent from "./stepperComponents/MappingComponent";
import SettingsComponent from "./stepperComponents/SettingsComponent";
import RequestsService from "@services/requests-service";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";

export default {
  name: "AefImport",
  mixins: [baseProps, localizationMixin],
  components: {
    FilesSelectionComponent,
    SettingsComponent,
    MappingComponent,
    ErrorDialogComponent,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      requestsService: null,
      e1: 0,
      headers: null,
      destinationFile: null,
      source: null,
      destination: null,
      mapping: null,
      loading: false,
      openErrorDialog: false,
      request: null,
    };
  },
  created() {
    this.requestsService = new RequestsService();

  },
  mounted: async function () {
    if (this.id != null) {
      this.loading = true;
      await this.getRequest(this.id);
      this.loading = false;
    } else {
      this.e1 = 1;
    }
  },
  methods: {
    async getRequest(id) {
      await this.requestsService.GetRequestsById(id).then((response) => {
        this.request = JSON.parse(response.data);
        if (this.request != null) {
          switch (this.request.State) {
            case 0:
              this.e1 = 1;
              break;
            case 1:
            case 2:
              this.e1 = 2;
              break;
            case 3:
            case 4:
            case 5:
            case 8:
            case 13:
              this.e1 = 3;
              break;
            case 6:
            case 9:
              this.openErrorDialog = true;
          }
        } else {
          this.e1 = 1;
        }
      });
    },
    setMappingObject(value) {
      this.mapping = value;
    },
    setRequest(value) {
      this.request = value;
      this.e1 = this.e1 + 1;
    },
  },
};
</script>
<style lang="scss">
.aef-import-view-class {
  height: 100%;
  background-color: #e6ebf4 !important;
  a:hover {
    color: #0056b3;
    text-decoration: none !important
  }
  .v-stepper__step.v-stepper__step--complete .v-stepper__step__step {
    background-color: green !important;
  }
  .column {
    max-width: 100%;
  }
  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }
  .v-input__control {
    border-radius: 0px !important;
  }
  .v-input__slot {
    background-color: white !important;
  }
  .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
