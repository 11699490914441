var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"aef-dashboard-view-class"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"6","md":"4","sm":"12"}},[_c('div',{staticClass:"title primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.Title"))+" ")])]),_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"6","md":"8","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-btn',{staticClass:"ma-2 text-none",attrs:{"small":"","color":"primary","to":{ name: 'AefImport' }}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.GoToGenerateDelivrable"))+" ")],1),_c('v-text-field',{attrs:{"dense":"","label":_vm.$translate('AefAutomate.Tdb.SearchInputLabel'),"outlined":"","color":"primary","hide-details":"","append-icon":"mdi-magnify","width":"150px","required":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.getRequests}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-reload")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.Refresh"))+" ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"pa-3",attrs:{"width":"100%"}},[_c('v-data-table',{staticClass:"data-table-class",attrs:{"headers":_vm.headers,"items":_vm.requests,"options":_vm.options,"server-items-length":_vm.totalRequests,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":_vm.tableFooterOptions},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.Status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(header.text))]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"v-icon mdi mdi-filter theme--light",staticStyle:{"margin-right":"30px","font-size":"18px"}},on))]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filterStatusItems),callback:function ($$v) {_vm.filterStatusItems=$$v},expression:"filterStatusItems"}},[_vm._l((_vm.allStatusItems),function(item){return [_c('v-list-item',{key:item.key,attrs:{"value":item.key,"active-class":"primary--text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{class:((item.color) + "--text"),domProps:{"textContent":_vm._s(item.value)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":item,"color":item.color}})],1)]}}],null,true)})]})],2)],1)],1)])]}},{key:"item.AccountLegalName",fn:function(ref){
var item = ref.item;
return [(item.Status == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px","cursor":"pointer"},on:{"click":function($event){return _vm.handleClickLegal(item)}}},on),[_vm._v(_vm._s(item.AccountLegalName))])]}}],null,true)},[_vm._v(" "+_vm._s(item.AccountLegalName)+" ")]):_c('span',[_vm._v(_vm._s(item.AccountLegalName))])]}},{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.CreatedAt))+" ")]}},{key:"item.DateClotureN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Status != 0 && item.Status != 6 && item.Status != 9 ? _vm.formatDate(item.DateClotureN) : "")+" ")]}},{key:"item.Method",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px","cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(item, 'KAEG')}}},on),[_vm._v(" KAEG ")])]}}],null,true)},[_vm._v(" KAEG ")])]}},{key:"item.edite",fn:function(ref){
var item = ref.item;
return [(item.Status == 1 || item.Status == 2)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editSeuilDialog(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-edit-outline")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(
              item.Sources != 'DATW' && (item.Status == 1 ||
                item.Status == 2 ||
                item.State == 6 ||
                item.State == 9 ||
                (item.Status == 0 &&
                  (item.State == 0 || item.State == 1 || item.State == 3)))

            )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteDialog(item.Id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}},{key:"item.CreatedBy",fn:function(ref){
            var item = ref.item;
return [(item.Sources == 'DATW')?_c('span',{staticStyle:{"cursor":"pointer","text-decoration":"underline"},attrs:{"onClick":"window.open(`https://digitalwebform.fr.kworld.kpmg.com/`, 'DATW', 'noopener');"}},[_vm._v(" Digital Factory ")]):_vm._e(),(item.Sources != 'DATW')?_c('span',[_vm._v(" "+_vm._s(item.CreatedBy)+" ")]):_vm._e()]}},{key:"item.Status",fn:function(ref){
            var item = ref.item;
return [(item.Status != 1 && item.Status != 2)?_c('v-row',{staticClass:"mx-3",attrs:{"align":"center","no-gutters":""}},[_c('v-icon',{attrs:{"color":_vm.allStatusItems.filter(function (i) { return i.key == item.Status; })[0]
                  .color,"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_c('div',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm.allStatusItems.filter(function (i) { return i.key == item.Status; })[0] .value)+" ")])],1):_c('v-select',{attrs:{"dense":"","items":_vm.statusItems,"item-value":"key","item-text":"value","outlined":"","hide-details":""},on:{"change":function($event){return _vm.requestStatusChange(item.Id)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-icon',{attrs:{"color":data.item.color,"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_c('div',{staticClass:"mx-3"},[_vm._v(_vm._s(data.item.value))])],1)]}},{key:"item",fn:function(data){return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-icon',{attrs:{"color":data.item.color,"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_c('div',{staticClass:"mx-3"},[_vm._v(_vm._s(data.item.value))])],1)]}}],null,true),model:{value:(item.Status),callback:function ($$v) {_vm.$set(item, "Status", $$v)},expression:"item.Status"}})]}},{key:"item.State",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-icon',{attrs:{"color":_vm.statesItems.filter(function (i) { return i.key == item.State; })[0].color,"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.statesItems.filter(function (i) { return i.key == item.State; })[0].value)+" ")])],1)]}},{key:"item.Delegations",fn:function(ref){
                  var item = ref.item;
return [_c('table',{staticClass:"table-children mb-2"},[_c('caption',{staticStyle:{"display":"none"}},[_vm._v("Delegations")]),_c('thead',{staticStyle:{"display":"none"}},[_c('tr',[_c('th')])]),_vm._l((item.Delegations),function(delegation,i){return [(i < 5)?_c('tr',{key:i},[_c('td',{staticClass:"table-child"},[_vm._v(" "+_vm._s(delegation.LastName)+", "+_vm._s(delegation.FirstName)+" ")])]):(i == 5)?_c('tr',{key:i},[_c('td',{staticClass:"table-child"},[_vm._v(".....")])]):_vm._e()]}),_c('tr',[(item.Status == 1)?_c('td',{staticClass:"table-child"},[_c('a',{staticClass:"add-delegation-button-class",staticStyle:{"color":"#00338d","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.showTeamMember(item.Id, item.Sources)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary"}},[_vm._v("mdi-pencil-outline")]),_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.EditDelegation"))+" ")],1)]):_vm._e()])],2)]}}])},[_c('v-data-footer',{attrs:{"items-per-page-text":"Nombres de lignes par page"}})],1)],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"title primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.EditTeamMembers.Title"))+" ")])])],1)],1)],1),_c('v-card-text',[_c('Team-Members-Management-Component',{attrs:{"requestId":_vm.selectedRequest,"sources":_vm.requestSelectedSources}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false;
          _vm.getRequests();}}},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Common.Buttons.Close"))+" ")])],1)],1)],1):_vm._e(),(_vm.dialogDeleteRequest)?_c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialogDeleteRequest),callback:function ($$v) {_vm.dialogDeleteRequest=$$v},expression:"dialogDeleteRequest"}},[_c('v-card',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"title primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.DeleteTitle"))+" ")])])],1)],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Tdb.DeleteText"))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialogDeleteRequest = false}}},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Common.No"))+" ")]),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary"},on:{"click":function($event){_vm.deleteRequest(_vm.requestIdToDelete);
          _vm.dialogDeleteRequest = false;
          _vm.getRequests();}}},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Common.Yes"))+" ")])],1)],1)],1):_vm._e(),(_vm.dialogEditSeuilRequest)?_c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialogEditSeuilRequest),callback:function ($$v) {_vm.dialogEditSeuilRequest=$$v},expression:"dialogEditSeuilRequest"}},[_c('v-card',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"title primary--text font-weight-bold"},[_vm._v(" Seuils d’audit (en euros) "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,false,3648563553)},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.$translate( "AefAutomate.Stepper.Settings.Tooltip2" ))+" ")])])],1)])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Matérialité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"","label":"Matérialité","outlined":"","error-messages":errors,"append-icon":"mdi-currency-eur","required":""},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.Materialite),callback:function ($$v) {_vm.Materialite=$$v},expression:"Materialite"}})]}}],null,false,1657324062)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Performance matérialité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"","label":"Performance matérialité","outlined":"","error-messages":errors,"append-icon":"mdi-currency-eur","required":""},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.PerfMaterialite),callback:function ($$v) {_vm.PerfMaterialite=$$v},expression:"PerfMaterialite"}})]}}],null,false,204084359)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"AMPT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"","label":"AMPT","outlined":"","error-messages":errors,"append-icon":"mdi-currency-eur","required":""},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.Ampt),callback:function ($$v) {_vm.Ampt=$$v},expression:"Ampt"}})]}}],null,false,3115404574)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialogEditSeuilRequest = false}}},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Common.Buttons.Cancel"))+" ")]),_c('v-btn',{staticClass:"text-none",attrs:{"loading":_vm.editRequestSeuilLoading,"color":"primary"},on:{"click":function($event){return _vm.updateRequestSeuil(_vm.requestIdToDelete);}}},[_vm._v(" "+_vm._s(_vm.$translate("AefAutomate.Common.Buttons.Confirm"))+" ")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }