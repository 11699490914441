<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Error-Dialog-Component :open="openErrorDialog"></Error-Dialog-Component>
    <v-row class="mt-3 settings-component-class">
      <v-col cols="12" md="12">
        <v-card height="100%">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.Settings.Title") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col class="mx-2" cols="12" md="3">
                  <validation-provider v-slot="{ errors }" name="Premier mois de l’exercice fiscal" rules="required">
                    <v-select v-model="request.PremierMoisExercice" menu-props="auto" :items="months" item-text="key"
                      item-value="value" label="Premier mois de l’exercice fiscal" outlined color="primary"
                      :error-messages="errors" hide-details required>
                      <template v-slot:append>
                        <v-row align="center" no-gutters>
                          <v-col cols="12" md="6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon class="mx-1" v-on="on" color="primary">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </template>
                              <div style="max-width: 300px">
                                {{
                                    $translate(
                                      "AefAutomate.Stepper.Settings.Tooltip1"
                                    )
                                }}
                              </div>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" md="6">

                            <v-icon class="mx-1">
                              mdi-menu-down
                            </v-icon>
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col class="py-0" cols="12" md="12">
                      <p class="primary--text title">
                        Seuils d’audit (en euros)
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mx-1" v-on="on" color="primary">
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                          <div style="max-width: 300px">
                            {{
                                $translate(
                                  "AefAutomate.Stepper.Settings.Tooltip2"
                                )
                            }}
                          </div>
                        </v-tooltip>
                      </p>
                    </v-col>
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" name="Matérialité">
                        <v-text-field v-model.lazy="Materialite" hide-details @keypress="onlyNumber"
                          label="Matérialité" outlined :error-messages="errors" append-icon="mdi-currency-eur" required>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" name="Performance matérialité">
                        <v-text-field v-model.lazy="PerfMaterialite" hide-details @keypress="onlyNumber"
                          label="Performance matérialité" outlined :error-messages="errors"
                          append-icon="mdi-currency-eur" required></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" name="AMPT">
                        <v-text-field v-model.lazy="Ampt" @keypress="onlyNumber" hide-details label="AMPT"
                          outlined :error-messages="errors" append-icon="mdi-currency-eur" required></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col class="py-0" cols="12" md="12">
                      <p class="primary--text title">Base N</p>
                    </v-col>
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" name="Format de la date" rules="required">
                        <v-select v-model="request.FormatDateN" hide-details :items="dataFormats"
                          label="Format de la date" outlined color="primary" :error-messages="errors" required>
                          <template v-slot:append>
                            <v-row align="center" no-gutters>
                              <v-col cols="12" md="6">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="mx-1" v-on="on" color="primary">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <div style="max-width: 300px">
                                    {{
                                        $translate(
                                          "AefAutomate.Stepper.Settings.Tooltip3"
                                        )
                                    }}
                                  </div>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12" md="6">

                                <v-icon class="mx-1">
                                  mdi-menu-down
                                </v-icon>
                              </v-col>

                            </v-row>
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <h5 class="primary--text">Aperçu date</h5>
                      <span> {{ dateComptableN }}</span>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" name="Date d’ouverture N" rules="required">
                            <v-text-field v-model="request.DateOuvertureN" hide-details label="Date d’ouverture N"
                              append-icon="mdi-calendar" outlined v-on="on" v-bind="attrs" placeholder="DD/MM/YYYY"
                              :error-messages="errors" @blur="dateOuvertureN = parseDate(request.DateOuvertureN)"
                              v-mask="'##/##/####'" required>
                            </v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker v-model="dateOuvertureN" for color="primary" no-title scrollable
                          @input="menu1 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" name="Date de cloture N" rules="required">
                            <v-text-field v-model="request.DateClotureN" hide-details label="Date de cloture N"
                              append-icon="mdi-calendar" outlined v-on="on" :error-messages="errors"
                              @blur="dateClotureN = parseDate(request.DateClotureN)" v-bind="attrs"
                              placeholder="DD/MM/YYYY" v-mask="'##/##/####'" required></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker v-model="dateClotureN" color="primary" no-title scrollable
                          @input="menu2 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="10">
                      <validation-provider v-slot="{ errors }" name="Code a nouveaux N" rules="required">
                        <v-autocomplete v-model="request.CodeANouveauN" hide-details :disabled="!request.AnouveauxBaseN"
                          :items="aNouveauxListN" item-value="value" item-text="value"
                          label="Code du journal des “à nouveaux”" outlined color="primary" :error-messages="errors"
                          required></v-autocomplete>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col class="py-0" cols="12" md="12">
                      <p class="primary--text title">Base N-1</p>
                    </v-col>
                    <v-col cols="12" md="8">
                      <validation-provider v-slot="{ errors }" name="Format de la date" rules="required">
                        <v-select v-model="request.FormatDateN_1" hide-details :items="dataFormats"
                          label="Format de la date" outlined color="primary" :error-messages="errors" required>
                          <template v-slot:append>
                            <v-row align="center" no-gutters>
                              <v-col cols="12" md="6">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="mx-1" v-on="on" color="primary">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <div style="max-width: 300px">
                                    {{
                                        $translate(
                                          "AefAutomate.Stepper.Settings.Tooltip3"
                                        )
                                    }}
                                  </div>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12" md="6">

                                <v-icon class="mx-1">
                                  mdi-menu-down
                                </v-icon>
                              </v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <h5 class="primary--text">Aperçu date</h5>
                      <span>{{ dateComptableN_1 }}</span>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" name="Date d’ouverture N-1" rules="required">
                            <v-text-field v-model="request.DateOuvertureN_1" hide-details label="Date d’ouverture N-1"
                              append-icon="mdi-calendar" outlined v-on="on"
                              @blur="dateOuvertureN_1 = parseDate(request.DateOuvertureN_1)" v-bind="attrs"
                              placeholder="DD/MM/YYYY" v-mask="'##/##/####'" :error-messages="errors" required>
                            </v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker v-model="dateOuvertureN_1" color="primary" no-title scrollable
                          @input="menu3 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider v-slot="{ errors }" name="Date de cloture N-1" rules="required">
                            <v-text-field v-model="request.DateClotureN_1" hide-details label="Date de cloture N-1"
                              append-icon="mdi-calendar" outlined v-on="on" :error-messages="errors"
                              @blur="dateClotureN_1 = parseDate(request.DateClotureN_1)" v-bind="attrs"
                              placeholder="DD/MM/YYYY" v-mask="'##/##/####'" required></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker v-model="dateClotureN_1" color="primary" no-title scrollable
                          @input="menu4 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="10">
                      <validation-provider v-slot="{ errors }" name="Code a nouveaux N-1" rules="required">
                        <v-autocomplete v-model="request.CodeANouveauN_1" hide-details
                          :disabled="!request.AnouveauxBaseN_1" :items="aNouveauxListN_1" item-value="value"
                          item-text="value" label="Code du journal des “à nouveaux”" outlined color="primary"
                          :error-messages="errors" required></v-autocomplete>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <div v-if="hideInputs" class="disable-inputs">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-card width="500">
                <v-row class="pa-3" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    {{ $translate("AefAutomate.Stepper.Settings.LoadingCube") }}
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
          <div v-if="request.State == 5" class="disable-inputs">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-card width="500">
                <v-row class="pa-3" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    {{
                        $translate("AefAutomate.Stepper.Settings.ProcessFinished")
                    }}
                  </v-col>
                  <v-col cols="12">
                    <v-row class="pa-3" align-content="center" justify="center">
                      <v-icon color="green" large>mdi-check-circle-outline</v-icon>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" class="text-none" text @click="openReport()">
                        {{
                            $translate(
                              "AefAutomate.Stepper.Settings.GoToTheReport"
                            )
                        }}
                        <v-icon right dark>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-row justify="center">
          <v-btn :disabled="invalid || hideInputs || request.State == 5" class="ma-2 text-none" color="primary"
            @click="nextStep">
            <v-icon left dark>mdi-arrow-right</v-icon>
            Enregistrer et passer à l’étape suivante
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </validation-observer>
</template>
<script>
import ErrorDialogComponent from "./ErrorDialogComponent";
import RequestsService from "@services/requests-service";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import { utilsMixin } from "@mixins/utils";
import { mask } from "vue-the-mask"

export default {
  mixins: [baseProps, localizationMixin, utilsMixin],
  props: {
    requestProp: Object,
  },
  components: {
    ErrorDialogComponent,
  },
  directives: { mask },
  data() {
    return {
      requestsService: null,
      powerBiConfig: null,
      urls:[],
      AefAutomateReport: window.AefAutomateReport,
      request: {},
      aNouveaux: [],
      valid: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      months: [
        { key: "Janvier", value: 1 },
        { key: "Fevrier", value: 2 },
        { key: "Mars", value: 3 },
        { key: "Avril", value: 4 },
        { key: "Mai", value: 5 },
        { key: "Juin", value: 6 },
        { key: "Juillet", value: 7 },
        { key: "Aout", value: 8 },
        { key: "Septembre", value: 9 },
        { key: "Octobre", value: 10 },
        { key: "Novembre", value: 11 },
        { key: "Decembre", value: 12 },
      ],
      dataFormats: [
        "DD/MM/YYYY",
        "MM/DD/YYYY",
        "YYYY/MM/DD",
        "YYYY/DD/MM",
        "DD-MM-YYYY",
        "MM-DD-YYYY",
        "YYYY-MM-DD",
        "YYYY-DD-MM",
        "DDMMYYYY",
        "MMDDYYYY",
        "YYYYMMDD",
        "YYYYDDMM",
      ],
      hideInputs: false,
      openErrorDialog: false,
      timerId: null,
      dateClotureN: null,
      dateClotureN_1: null,
      dateOuvertureN: null,
      dateOuvertureN_1: null,
      dateComptableN: "",
      dateComptableN_1: "",
      Materialite: "0",
      PerfMaterialite: "0",
      Ampt: "0",
    };
  },
  computed: {
    aNouveauxListN() {
      if (this.aNouveaux != null) {
        let array = this.aNouveaux.filter((h) => h.source == "BaseN");
        array.unshift("Aucune correspondance");
        return array;
      }
      return [];
    },
    aNouveauxListN_1() {
      if (this.aNouveaux != null) {
        let array = this.aNouveaux.filter((h) => h.source == "BaseN_1");
        array.unshift("Aucune correspondance");
        return array;
      }
      return [];
    },
  },
  async created() {
    this.requestsService = new RequestsService();

  },
  async mounted() {
    this.request = this.requestProp;
    // this.request.DateOuvertureN = this.request.DateOuvertureN != null ? new Date(this.request.DateOuvertureN).toLocaleDateString() : new Date().toLocaleDateString()
    //this.request.DateClotureN = this.request.DateClotureN != null ? new Date(this.request.DateClotureN).toLocaleDateString() : new Date().toLocaleDateString()
    //this.request.DateOuvertureN_1 = this.request.DateOuvertureN_1 != null ? new Date(this.request.DateOuvertureN_1).toLocaleDateString() : new Date().toLocaleDateString()
    //this.request.DateClotureN_1 = this.request.DateClotureN_1 != null ? new Date(this.request.DateClotureN_1).toLocaleDateString() : new Date().toLocaleDateString()


    if (this.request.State == 4) {
      await this.getRequest(this.request.Id);
    }
    await this.getAnouveaux(this.request.Id);
    if (!this.request.AnouveauxBaseN)
      this.request.CodeANouveauN = "Aucune correspondance";

    if (!this.request.AnouveauxBaseN_1)
      this.request.CodeANouveauN_1 = "Aucune correspondance";
  },
  beforeDestroy() {
    if (this.timerId != null) clearInterval(this.timerId);
  },
  watch: {
    dateOuvertureN(val) {
      this.request.DateOuvertureN = this.formatDatePicker(val)
    },
    dateOuvertureN_1(val) {
      this.request.DateOuvertureN_1 = this.formatDatePicker(val)
    },
    dateClotureN(val) {
      this.request.DateClotureN = this.formatDatePicker(val)
    },
    dateClotureN_1(val) {
      this.request.DateClotureN_1 = this.formatDatePicker(val)
    },
    Materialite(val) {
      if (val != null) this.changeFormatMaterialite();
    },
    Ampt(val) {
      if (val != null ) this.changeFormatAmpt();
    },
    PerfMaterialite(val) {
      if (val != null ) this.changeFormatPerfMaterialite();
    }

  },
  methods: {
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    changeFormatPerfMaterialite() {
      const numberFormat = new Intl.NumberFormat();
      this.PerfMaterialite = numberFormat.format(
        this.PerfMaterialite.replace(/\s/g, "")
      )
    },
    changeFormatAmpt() {
      const numberFormat = new Intl.NumberFormat();
      this.Ampt = numberFormat.format(
        this.Ampt.replace(/\s/g, "")
      )
    },
    changeFormatMaterialite() {
      const numberFormat = new Intl.NumberFormat();
      this.Materialite = numberFormat.format(
        this.Materialite.replace(/\s/g, "")
      )
    },
    async openReport() {
        let url = null;
        url = JSON.parse((await this.requestsService.GetUrlRapportByRequestId(this.request.Id)).data);
        if (url == null) {
            return;
        }
        window.open(
        url?.kaeg 
        );
    },
    async getAnouveaux(requestId) {
      await this.requestsService.GetAnouveaux(requestId).then((response) => {
        let result = JSON.parse(response.data)
        this.aNouveaux = result.anouveaux;
        this.dateComptableN = result.dateComptableN;
        this.dateComptableN_1 = result.dateComptableN_1;
      });
    },
    async getRequest(id) {
      let self = this;
      this.hideInputs = true;
      await this.requestsService.GetRequestsById(id).then((response) => {
        this.request = JSON.parse(response.data);
        this.request.DateOuvertureN = new Date(this.request.DateOuvertureN).toLocaleDateString()
        this.request.DateClotureN = new Date(this.request.DateClotureN).toLocaleDateString()
        this.request.DateOuvertureN_1 = new Date(this.request.DateOuvertureN_1).toLocaleDateString()
        this.request.DateClotureN_1 = new Date(this.request.DateClotureN_1).toLocaleDateString()

      });

      if (this.request.State == 4) {
        self.timerId = setInterval(async () => {
          self.hideInputs = true;
          await this.requestsService.GetRequestsById(id).then((response) => {
            this.request = JSON.parse(response.data);
            this.request.DateOuvertureN = new Date(this.request.DateOuvertureN).toLocaleDateString()
            this.request.DateClotureN = new Date(this.request.DateClotureN).toLocaleDateString()
            this.request.DateOuvertureN_1 = new Date(this.request.DateOuvertureN_1).toLocaleDateString()
            this.request.DateClotureN_1 = new Date(this.request.DateClotureN_1).toLocaleDateString()


            if (this.request.State == 9) {
              self.openErrorDialog = true;
            }
            if (this.request.State == 5) {
              self.hideInputs = false;
              clearInterval(self.timerId);
              self.timerId = null;
            }
          });
        }, 20000);
      } else {
        this.hideInputs = false;

      }
    },
    async nextStep() {
      this.hideInputs = true;
      
      this.request.Materialite = parseFloat(
        this.Materialite.replace(/\s/g, "")
      );
      this.request.PerfMaterialite = parseFloat(
        this.PerfMaterialite.replace(/\s/g, "")
      );
      this.request.Ampt = parseFloat(this.Ampt.replace(/\s/g, ""));

      this.request.DateOuvertureN = this.parseDate(this.request.DateOuvertureN)
      this.request.DateClotureN = this.parseDate(this.request.DateClotureN)
      this.request.DateOuvertureN_1 = this.parseDate(this.request.DateOuvertureN_1)
      this.request.DateClotureN_1 = this.parseDate(this.request.DateClotureN_1)
      await this.requestsService
        .UpdateRequest(this.request.Id, this.request)
        .then(() => { /* TODO document why this arrow function is empty */ });
      await this.requestsService.GenerateData(this.request.Id).then(() => { /* TODO document why this arrow function is empty */ });
      await this.getRequest(this.request.Id);
    },
  },
};
</script>
<style lang="scss">
.settings-component-class {
  a:hover {
    color: #0056b3;
    text-decoration: none !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    font-weight: 600;
  }

  .disable-inputs {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }

  .v-input__control {
    border-radius: 0px !important;
  }

  .v-input__slot {
    background-color: white !important;
  }

  .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-weight: 600;
  }
}
</style>
