/* eslint-disable */

/**
 * 
 * @param {number} chunkSize - chunk upload size (default 10485760)
 * @param {object} httpService - default is axios. If you want to change it you have to adapt the options paramater.
 */
export const FileUploader = (
    file,
    fileMetadata,
    httpService,
    uploadChunkUrl,
    commitUrl,
    chunkSize = 10485760) => {

    let fileQueue = {};
    let progressEvent;

    const chunkify = (file, start) => {
        const end = file.size;
        if (start >= end) {
            return {
                success: false,
                result: null
            };
        }
        const chunkStart = start;
        const chunkEnd = Math.min(start + chunkSize, end);
        return {
            success: true,
            chunkEnd,
            result: file.slice(chunkStart, chunkEnd)
        };
    }

    const uploadChunk = async (fileEntry) => {

        if (fileEntry.chunkQueue.length <= 0) {
            await commitFile(fileEntry);
            return;
        }

        const chunkEntry = fileEntry.chunkQueue.pop();
        
        await httpService({
            url: uploadChunkUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/octet-stream',
                'X-Content-Type': file.type,
                'X-Content-Id': fileMetadata.fileId,
                'X-Chunk-Id': chunkEntry.index,
            },
            data: chunkEntry.data
        })
            .then(async () => {
                const chunk = chunkify(fileEntry.file, chunkEntry.start);

                if (chunk.success) {
                    fileEntry.chunkQueue.push({
                        index: chunkEntry.index + 1,
                        data: chunk.result,
                        start: chunk.chunkEnd
                    })

                    if (progressEvent) {
                        const progress = ((chunkEntry.start / fileEntry.file.size) * 100).toFixed(2);
                        progressEvent(fileEntry.fileId, fileEntry.file.name, progress);
                    }
                }

                await uploadChunk(fileEntry);
            })
            .catch(error => {
                progressEvent(null, error, -100)
            });
    }

    const uploadFile = async () => {
        const entry = fileQueue;
        if (!entry) {
            return;
        }
        const chunk = chunkify(entry.file, 0);
        if (chunk.success) {
            entry.chunkQueue.push({
                index: 0,
                data: chunk.result,
                start: chunk.chunkEnd
            });
            await uploadChunk(entry);
        }
    }

    const commitFile = async (fileEntry) => {
        await httpService({
            url: commitUrl,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Content-Id': fileMetadata.fileId
            },
            data: JSON.stringify({
                fileName: fileEntry.file.name,
                size: fileEntry.file.size
            })
        })
            .then((res) => {
                progressEvent(fileEntry.fileId, res, 100);
            })
            .catch(e => {
                progressEvent(null, e, -100)
            });
    }

    return {
        upload: async () => {
            fileQueue = {
                file,
                chunkQueue: [],
                ...fileMetadata
            };
            await uploadFile();
        },
        reportProgress: (handle) => {
            progressEvent = handle;
        }        
    };
}