<template>
  <v-app class="aef-faq">
    <v-container fluid>
      <object  :data="link" type="application/pdf" width="100%" height="800px"/>
    </v-container>
  </v-app>
</template>

<script>
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import { utilsMixin } from "@mixins/utils";
import FaqsService from "@services/faqs-service";

export default {
  name: "AefFaq",
  mixins: [baseProps, localizationMixin, utilsMixin],
  data() {
    return {
      faqsService: null,
      search: "",
      loading: false,
      data: [],
      link: ""
    };
  },
  created() {
    this.faqsService = new FaqsService();
  },
  async mounted() {
   await this.getFaqs();
  },
  methods: {
  
    async getFaqs() {
      this.loading = true;
      await this.faqsService.GetFaqs().then((response) => {
        this.link = response;
      });
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.aef-faq {
  height: 100%;
  background-color: #e6ebf4 !important;

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }

  .v-input__control {
    border-radius: 0px !important;
  }

  .v-input__slot {
    background-color: white !important;
  }

  .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .faq-table-class {
    width: 100%;

    .v-expansion-panels {
      background-color: rgba(0, 51, 141, 0.1) !important;
      border-radius: 0px;
      padding: 0;
      margin: 0;

      .v-expansion-panel {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }

    td:first-child {
      width: 30%;
      background: #00338d;
      color: white;
      margin: 20px;
      padding: 20px;
    }

    .icon {
      order: 0;
    }

    .header {
      order: 1;
    }
  }
}
</style>