<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-row class="file-selection-component-class mt-3">
      <v-col cols="12" lg="4" xl="4">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.UploadFiles.SelectClient") }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mx-1" v-on="on" color="primary">
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 300px">
                    <div>
                      {{
                      $translate(
                      "AefAutomate.Stepper.UploadFiles.Tooltip1.Text1"
                      )
                      }}
                    </div>
                    <div>
                      {{
                      $translate(
                      "AefAutomate.Stepper.UploadFiles.Tooltip1.Text2"
                      )
                      }}
                    </div>
                    <div>
                      {{
                      $translate(
                      "AefAutomate.Stepper.UploadFiles.Tooltip1.Text3"
                      )
                      }}
                    </div>
                    <div>
                      {{
                      $translate(
                      "AefAutomate.Stepper.UploadFiles.Tooltip1.Text4"
                      )
                      }}
                    </div>
                  </div>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-col cols="12" md="12">
            <validation-provider v-slot="{ errors }" :name="
              $translate(
                'AefAutomate.Stepper.UploadFiles.AccountNameInputLabel'
              )
            " rules="required">
              <v-autocomplete v-model="account" :loading="loading" :items="accounts" :search-input.sync="search"
                no-filter outlined item-text="document.AccountLegalName" hide-no-data color="primary" return-object
                :error-messages="errors" hide-details required>
                <template v-slot:label>
                  {{ $translate(
                  'AefAutomate.Stepper.UploadFiles.AccountNameInputLabel'
                  )
                  }} <span class="red--text">*</span>
                </template>
              </v-autocomplete>
            </validation-provider>
          </v-col>
        </v-card>
        <v-card class="mt-3">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.UploadFiles.NewReport") }}
                <span class="red--text"> *</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mx-1" v-on="on" color="primary">
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 300px">
                    {{ $translate("AefAutomate.Stepper.UploadFiles.Tooltip2") }}
                  </div>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <img v-if="radioGroupN_1 == false" src="../../../assets/warning.svg" alt="" />
            </v-list-item-action>
          </v-list-item>
          <v-row class="mx-2" no-gutters>
            <v-col cols="12" md="6">
              <p class="mx-2 hidden-sm-and-down primary--text mr-2">
                {{
                $translate(
                "AefAutomate.Stepper.UploadFiles.NewReportBaseNMessage"
                )
                }}
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p class="mx-2 hidden-sm-and-down primary--text mr-2">
                {{
                $translate(
                "AefAutomate.Stepper.UploadFiles.NewReportBaseN_1Message"
                )
                }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mx-2" no-gutters>
            <v-col cols="12" md="6">
              <div class="mx-2">
                <p class="hidden-md-and-up primary--text">
                  {{
                  $translate(
                  "AefAutomate.Stepper.UploadFiles.NewReportBaseNMessage"
                  )
                  }}
                </p>
                <validation-provider v-slot="{ errors }"
                  name="$translate('AefAutomate.Stepper.UploadFiles.AnouveauxBaseN')" rules="required">
                  <v-radio-group v-model="radioGroupN" :error-messages="errors" required>
                    <v-radio :label="$translate('AefAutomate.Common.Yes')" :value="true"></v-radio>
                    <v-radio :label="$translate('AefAutomate.Common.No')" :value="false"></v-radio>
                  </v-radio-group>
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mx-2">
                <p class="hidden-md-and-up primary--text mr-2">
                  {{
                  $translate(
                  "AefAutomate.Stepper.UploadFiles.NewReportBaseN_1Message"
                  )
                  }}
                </p>
                <validation-provider v-slot="{ errors }" name="A nouveaux base N-1" rules="required">
                  <v-radio-group v-model="radioGroupN_1" :error-messages="errors" required>
                    <v-radio :label="$translate('AefAutomate.Common.Yes')" :value="true"></v-radio>
                    <v-radio :label="$translate('AefAutomate.Common.No')" :value="false"></v-radio>
                  </v-radio-group>
                </validation-provider>
                <v-row no-gutters v-if="radioGroupN_1 == false">
                  <p style="color: #bc204b">
                    En l'absence des à nouveaux dans la base N-1, il convient
                    d'insérer la balance générale N-2 dans l'onglet
                    <span class="font-weight-bold">Insert_BG N-2</span> des AEF
                    pour récupérer le bilan d'ouverture.
                  </p>
                  <p class="font-weight-bold" style="color: #bc204b">
                    > En l'absence de cette information, AEF BI ne sera pas
                    exploitable.
                  </p>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8" xl="8">
        <v-card height="100%">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.UploadFiles.SelectFiles") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-form ref="form" v-model="valid">
            <v-layout column class="align-center" no-gutters>
              <v-col lg="10" xl="10">
                <v-row class="py-0 px-3" justify="center">
                  <v-col :cols="
                    (fileBaseNExtension != null &&
                      fileBaseNExtension == 'txt') ||
                      fileBaseNExtension == 'csv'
                      ? 4
                      : 10
                  ">
                    <validation-provider v-slot="{ errors }" name="Base N" rules="required">
                      <v-file-input v-model="fileBaseN" :accept="mimeType" outlined prepend-icon="" color="primary"
                        :error-messages="errors" hide-details required>
                        <template v-slot:label>
                          Base N <span class="red--text">*</span>
                        </template>
                        <template v-slot:append>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="6">
                              <v-icon class="mx-1">
                                mdi-folder-open-outline
                              </v-icon>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon class="mx-1" v-on="on" color="primary">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </template>
                                <div style="max-width: 300px">
                                  {{
                                  $translate(
                                  "AefAutomate.Stepper.UploadFiles.Tooltip3"
                                  )
                                  }}
                                </div>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-file-input>
                    </validation-provider>
                  </v-col>
                  <v-col class="pa-0" v-if="
                    (fileBaseNExtension != null &&
                      fileBaseNExtension == 'txt') ||
                    fileBaseNExtension == 'csv'
                  " cols="6">
                    <v-row>
                      <v-col cols="6">
                        <validation-provider v-slot="{ errors }" :name="
                          $translate('AefAutomate.Stepper.UploadFiles.Delimiter')
                        " rules="required">
                          <v-select v-model="fileDelimiterBaseN" :items="delimiters" item-text="key" item-value="value"
                            outlined color="primary" :error-messages="errors" hide-details required>
                            <template v-slot:label>
                              {{ $translate(
                              'AefAutomate.Stepper.UploadFiles.Delimiter'
                              )
                              }} <span class="red--text">*</span>
                            </template>
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6">
                        <v-select clearable v-model="fileEncodingBaseN" :items="encodingList" item-text="value"
                          item-value="key" :label="
                            $translate(
                              'AefAutomate.Stepper.UploadFiles.Encoding.Title'
                            )
                          " outlined color="primary" hide-details required>
                          <template v-slot:append>
                            <v-row align="center" no-gutters>

                              <v-col cols="12" md="6">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="mx-1" v-on="on" color="primary">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <div style="max-width: 300px">
                                    {{
                                    $translate(
                                    "AefAutomate.Stepper.UploadFiles.Encoding.Tooltip"
                                    )
                                    }}
                                  </div>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row class="fill-height" justify="start" align="center" no-gutters>
                      <v-progress-circular class="mx-1" v-if="progressN == 0" :width="3" color="primary" indeterminate>
                      </v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="progressN > 0 && progressN < 100" :width="3"
                        color="primary" :value="progressN">{{ progressN }}</v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="!uploadedN && progressN == 100" :width="3" color="primary"
                        indeterminate>{{ progressN }}</v-progress-circular>
                      <v-icon class="mx-1" v-if="uploadedN && progressN == 100" color="green">mdi-check</v-icon>
                      <v-icon class="mx-1" v-if="errorUploadedN" color="red">mdi-alert-circle-outline</v-icon>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="10" xl="10">
                <v-row justify="center" class="py-0 px-3">
                  <v-col :cols="
                    (fileBaseN_1Extension != null &&
                      fileBaseN_1Extension == 'txt') ||
                      fileBaseN_1Extension == 'csv'
                      ? 4
                      : 10
                  ">
                    <validation-provider v-slot="{ errors }" name="Base N-1" rules="required">
                      <v-file-input v-model="fileBaseN_1" :accept="mimeType" outlined prepend-icon="" color="primary"
                        :error-messages="errors" hide-details required>
                        <template v-slot:label>
                          Base N-1 <span class="red--text">*</span>
                        </template>
                        <template v-slot:append>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="6">
                              <v-icon class="mx-1">
                                mdi-folder-open-outline
                              </v-icon>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon class="mx-1" v-on="on" color="primary">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </template>
                                <div style="max-width: 300px">
                                  {{
                                  $translate(
                                  "AefAutomate.Stepper.UploadFiles.Tooltip3"
                                  )
                                  }}
                                </div>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-file-input>
                    </validation-provider>
                  </v-col>
                  <v-col class="pa-0" v-if="
                    (fileBaseN_1Extension != null &&
                      fileBaseN_1Extension == 'txt') ||
                    fileBaseN_1Extension == 'csv'
                  " cols="6">
                    <v-row>
                      <v-col cols="6">
                        <validation-provider v-slot="{ errors }" :name="
                          $translate('AefAutomate.Stepper.UploadFiles.Delimiter')
                        " rules="required">
                          <v-select v-model="fileDelimiterBaseN_1" :items="delimiters" item-text="key"
                            item-value="value" outlined color="primary" :error-messages="errors" hide-details required>
                            <template v-slot:label>
                              {{ $translate(
                              'AefAutomate.Stepper.UploadFiles.Delimiter'
                              )
                              }} <span class="red--text">*</span>
                            </template>
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6">
                        <v-select clearable v-model="fileEncodingBaseN_1" :items="encodingList" item-text="value"
                          item-value="key" :label="
                            $translate(
                              'AefAutomate.Stepper.UploadFiles.Encoding.Title'
                            )
                          " outlined color="primary" hide-details>
                          <template v-slot:append>
                            <v-row align="center" no-gutters>

                              <v-col cols="12" md="6">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="mx-1" v-on="on" color="primary">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <div style="max-width: 300px">
                                    {{
                                    $translate(
                                    "AefAutomate.Stepper.UploadFiles.Encoding.Tooltip"
                                    )
                                    }}
                                  </div>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row class="fill-height" align="center" justify="start" no-gutters>
                      <v-progress-circular class="mx-1" v-if="progressN_1 == 0" :width="3" color="primary"
                        indeterminate></v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="progressN_1 > 0 && progressN_1 < 100" :width="3"
                        color="primary" :value="progressN_1">{{ progressN_1 }}</v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="!uploadedN_1 && progressN_1 == 100" :width="3"
                        color="primary" indeterminate>{{ progressN_1 }}</v-progress-circular>
                      <v-icon class="mx-1" v-if="uploadedN_1 && progressN_1 == 100" color="green">mdi-check
                      </v-icon>
                      <v-icon class="mx-1" v-if="errorUploadedN_1" color="red">mdi-alert-circle-outline</v-icon>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="10" xl="10">
                <v-row justify="center" class="py-0 px-3">
                  <v-col :cols="
                    (fileMappingAefExtension != null &&
                      fileMappingAefExtension == 'txt') ||
                      fileMappingAefExtension == 'csv'
                      ? 4
                      : 10
                  ">
                    <validation-provider v-slot="{ errors }" name="AEF" rules="required">
                      <v-file-input v-model="fileMappingAef" :accept="mimeType" outlined prepend-icon=""
                        append-icon="mdi-folder-open-outline" color="primary" :error-messages="errors" hide-details
                        required>
                        <template v-slot:label>
                          AEF <span class="red--text">*</span>
                        </template>
                        <template v-slot:append>
                          <v-row align="center" no-gutters>
                            <v-col cols="12" md="6">
                              <v-icon class="mx-1">
                                mdi-folder-open-outline
                              </v-icon>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon class="mx-1" v-on="on" color="primary">
                                    mdi-alert-circle-outline
                                  </v-icon>
                                </template>
                                <div style="max-width: 300px">
                                  {{
                                  $translate(
                                  "AefAutomate.Stepper.UploadFiles.Tooltip4"
                                  )
                                  }}
                                </div>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-file-input>
                    </validation-provider>
                  </v-col>
                  <v-col class="pa-0" v-if="
                    (fileMappingAefExtension != null &&
                      fileMappingAefExtension == 'txt') ||
                    fileMappingAefExtension == 'csv'
                  " cols="6">
                    <v-row>
                      <v-col cols="6">
                        <validation-provider v-slot="{ errors }" :name="
                          $translate('AefAutomate.Stepper.UploadFiles.Delimiter')
                        " rules="required">
                          <v-select v-model="fileDelimiterMappingAef" :items="delimiters" item-text="key"
                            item-value="value" outlined color="primary" :error-messages="errors" hide-details required>
                            <template v-slot:label>
                              {{ $translate(
                              'AefAutomate.Stepper.UploadFiles.Delimiter'
                              )
                              }} <span class="red--text">*</span>
                            </template>
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6">
                        <v-select clearable v-model="fileEncodingMappingAef" :items="encodingList" item-text="value"
                          item-value="key" :label="
                            $translate(
                              'AefAutomate.Stepper.UploadFiles.Encoding.Title'
                            )
                          " outlined color="primary" hide-details>
                          <template v-slot:append>
                            <v-row align="center" no-gutters>

                              <v-col cols="12" md="6">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon class="mx-1" v-on="on" color="primary">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </template>
                                  <div style="max-width: 300px">
                                    {{
                                    $translate(
                                    "AefAutomate.Stepper.UploadFiles.Encoding.Tooltip"
                                    )
                                    }}
                                  </div>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row class="fill-height" align="center" justify="start" no-gutters>
                      <v-progress-circular class="mx-1" v-if="progressAef == 0" :width="3" color="primary"
                        indeterminate></v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="progressAef > 0 && progressAef < 100" :width="3"
                        color="primary" :value="progressAef">{{ progressAef }}</v-progress-circular>
                      <v-progress-circular class="mx-1" v-if="!uploadedAef && progressAef == 100" :width="3"
                        color="primary" indeterminate>{{ progressAef }}</v-progress-circular>
                      <v-icon class="mx-1" v-if="uploadedAef && progressAef == 100" color="green">mdi-check
                      </v-icon>
                      <v-icon class="mx-1" v-if="errorUploadedAef" color="red">mdi-alert-circle-outline</v-icon>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

            </v-layout>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-if="fileBaseNExtension == 'csv' || fileBaseNExtension == 'txt'" cols="6">
            <v-card>
              <v-card-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.UploadFiles.OverviewFileN")}}
              </v-card-title>

              <v-list style="max-height: 200px" class="overflow-y-auto">
                <template v-for="(line, index) in previewFileBaseN">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <span>{{ line }}</span>
                    </v-list-item-content>

                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>
          <v-col v-if="fileBaseN_1Extension == 'csv' || fileBaseN_1Extension == 'txt'" cols="6">
            <v-card>
              <v-card-title class="title primary--text font-weight-bold">
                {{ $translate("AefAutomate.Stepper.UploadFiles.OverviewFileN_1")}}

              </v-card-title>

              <v-list style="max-height: 200px" class="overflow-y-auto">
                <template v-for="(line, index) in previewFileBaseN_1">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <span>{{ line }}</span>
                    </v-list-item-content>

                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-row justify="center">
          <v-btn class="ma-2 text-none" color="primary" :loading="uploadingFiles" :disabled="invalid || uploadingFiles"
            @click="upload()">
            <v-icon left dark>mdi-arrow-right</v-icon>
            {{ $translate("AefAutomate.Common.Buttons.SaveAndGoToNextStep") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-if="existanceByAccountDialog" v-model="existanceByAccountDialog" persistent width="800">
      <v-card>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
                <div class="title primary--text font-weight-bold">
                  {{
                  $translate(
                  "AefAutomate.Stepper.UploadFiles.RequestExistance.Title"
                  )
                  }}
                </div>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="
  account = null;
existingRequest = {};
existanceByAccountDialog = false;
            ">
              <img src="../../../assets/close.svg" alt="" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-card-text>
          <v-row class="pb-5" no-gutters justify="center">
            <img src="../../../assets/warning.svg" alt="" />
          </v-row>
          <v-row class="mx-6">
            <v-col cols="12" md="12">
              <div class="black--text">
                Un livrable AEF-BI a déjà été généré :
              </div>
              <div class="black--text">
                Par
                <span class="font-weight-bold">{{
                existingRequest.ContactFullName
                }}</span>
                le
                <span class="font-weight-bold">{{
                formatDate(existingRequest.CreatedAt)
                }}</span>
                pour le client
                {{ existingRequest.AccountLegalName }}(
                <span class="font-weight-bold">{{
                existingRequest.AccountNumber
                }}</span>)
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div class="black--text">
                En poursuivant cette action, les données chargées seront
                effacées sans retour possible.
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div class="black--text font-italic">
                Vous pouvez demander une délégation en cas de besoin à
                {{ existingRequest.ContactFullName }} ou continuer si vous êtes
                sure.
              </div>
            </v-col>
          </v-row>
          <!--{{ existingRequest.CreatedBy + $translate("AefAutomate.Stepper.UploadFiles.RequestExistanceMessage") }}-->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row no-gutters align="center">
            <v-btn text class="ma-2 text-none" color="primary" @click="
  account = null;
existingRequest = {};
existanceByAccountDialog = false;
            ">
              <v-icon left dark>mdi-arrow-left</v-icon>
              {{
              $translate(
              "AefAutomate.Stepper.UploadFiles.RequestExistance.CloseAndSelectNewclient"
              )
              }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none" @click="
  existingRequest = {};
existanceByAccountDialog = false;
            ">
              {{
              $translate(
              "AefAutomate.Stepper.UploadFiles.RequestExistance.ContinueWithClient"
              ) +
              " " +
              existingRequest.AccountNumber
              }}
              <v-icon right dark>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>
<script>
import UploadService from "@services/upload-service";
import RequestsService from "@services/requests-service";
import { utilsMixin } from "@mixins/utils";
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";

export default {
  mixins: [baseProps, localizationMixin, utilsMixin],
  data() {
    return {
      uploadService: null,
      requestsService: null,
      existingRequest: {},
      existanceByAccountDialog: false,
      uploadingFiles: false,
      mimeType:
        ".txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12",
      valid: false,
      progressN: -1,
      progressN_1: -1,
      progressAef: -1,
      uploadedN: false,
      uploadedN_1: false,
      uploadedAef: false,
      errorUploadedN: false,
      errorUploadedN_1: false,
      errorUploadedAef: false,
      message: "",
      fileBaseN: null,
      fileBaseN_1: null,
      fileMappingAef: null,
      fileDelimiterBaseN: null,
      fileDelimiterBaseN_1: null,
      fileDelimiterMappingAef: null,
      fileEncodingBaseN: null,
      fileEncodingBaseN_1: null,
      fileEncodingMappingAef: null,
      radioGroupN: null,
      radioGroupN_1: null,
      loading: false,
      search: null,
      account: null,
      accounts: [],
      encodingList: [
        {
          key: "windows-1252",
          value: "Aperçu 1"
        },
        {
          key: "UTF-8",
          value: "Aperçu 2"
        },
        {
          key: "UTF-16",
          value: "Aperçu 3"
        },
        {
          key: "UTF-32",
          value: "Aperçu 4"
        }
      ],
      previewFileBaseN: "",
      previewFileBaseN_1: ""
    };
  },
  computed: {
    delimiters() {
      return [
        { key: this.$translate("AefAutomate.Common.Semicolon"), value: ";" },
        { key: this.$translate("AefAutomate.Common.Comma"), value: "," },
        {
          key: this.$translate("AefAutomate.Common.Tabulation"),
          value: "Tab",
        },
        { key: this.$translate("AefAutomate.Common.Pipe"), value: "|" },
      ];
    },
    fileBaseNExtension() {
      return this.fileBaseN
        ? this.fileBaseN.name.split(".").at(-1).toLowerCase()
        : null;
    },
    fileBaseN_1Extension() {
      return this.fileBaseN_1
        ? this.fileBaseN_1.name.split(".").at(-1).toLowerCase()
        : null;
    },
    fileMappingAefExtension() {
      return this.fileMappingAef
        ? this.fileMappingAef.name.split(".").at(-1).toLowerCase()
        : null;
    },
  },
  watch: {
    fileBaseN(value){
      if (value != null && this.fileEncodingBaseN!= null)
        this.readFileBaseN(this.fileEncodingBaseN);
    },
    fileBaseN_1(value){
      if (value != null && this.fileEncodingBaseN_1!= null)
        this.readFileBaseN_1(this.fileEncodingBaseN_1);
    },
    fileEncodingBaseN(value) {
      if (value != null)
        this.readFileBaseN(value);
    },
    fileEncodingBaseN_1(value) {
      if (value != null)
        this.readFileBaseN_1(value);
    },
    fileBaseNExtension(value) {
      this.fileDelimiterBaseN = null;
      this.fileEncodingBaseN = "windows-1252";
      this.previewFileBaseN = null;
      if (value != null && (value == 'csv' || value == 'txt')) this.readFileBaseN();
    },
    fileMappingAefExtension(value) {
      if (value == 'csv' || value == "txt") this.fileEncodingMappingAef = "windows-1252";
    },
    fileBaseN_1Extension(value) {
      this.fileDelimiterBaseN_1 = null;
      this.fileEncodingBaseN_1 = "windows-1252";
      this.previewFileBaseN_1 = null;
      if (value != null && (value == 'csv' || value == 'txt')) this.readFileBaseN_1();
    },
    search(val) {
      if (val && val.length >= 3) this.querySelections(val);
    },
    account(val) {
      this.checkRequestExistanceByAccount(val.document.AccountNumber);
    }
  },
  created() {
    this.uploadService = new UploadService();
    this.requestsService = new RequestsService();
  },
  methods: {
    readFileBaseN(encoding = "windows-1252") {
      let reader = new FileReader();
      let file = this.fileBaseN.slice(0, 1024);
      reader.readAsText(file, encoding);
      reader.onload = () => {
        this.previewFileBaseN = reader.result.split(/\r\n|\n/, 5);
      }
    },
    readFileBaseN_1(encoding = "windows-1252") {
      let reader = new FileReader();
      let file = this.fileBaseN_1.slice(0, 1024);
      reader.readAsText(file, encoding);
      reader.onload = () => {
        this.previewFileBaseN_1 = reader.result.split(/\r\n|\n/, 5);
      }
    },
    async checkRequestExistanceByAccount(val) {
      this.existingRequest = {};
      await this.requestsService
        .CheckRequestExistanceByAccount(val)
        .then(async (response) => {
          if (response.data != null) {
            let request = JSON.parse(response.data);
            this.existingRequest["AccountNumber"] = request.AccountNumber;
            this.existingRequest["AccountLegalName"] = request.AccountLegalName;
            this.existingRequest["CreatedAt"] = request.CreatedAt;
            await this.requestsService
              .GetContactByEmail(request.CreatedBy)
              .then((resp) => {
                this.existingRequest["ContactFullName"] =
                  resp.data.values[0].ContactFullName;
                this.existingRequest["ContactEmailOffice"] =
                  resp.data.values[0].ContactEmailOffice;
                this.existanceByAccountDialog = true;
              });
          }
        });
    },
    querySelections(v) {
      this.loading = true;

      this.requestsService.GetAccounts(v).then((response) => {
        this.accounts = JSON.parse(response.data);
        this.loading = false;
      });
    },
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
    },
    async upload() {
      this.uploadingFiles = true;

      this.progressN = -1;
      this.progressN_1 = -1;
      this.progressAef = -1;

      this.uploadedN = false;
      this.uploadedN_1 = false;
      this.uploadedAef = false;

      this.errorUploadedN = false;
      this.errorUploadedN_1 = false;
      this.errorUploadedAef = false;

      let request = null;
      await this.requestsService
        .CreateRequest({
          AnouveauxBaseN: this.radioGroupN,
          AnouveauxBaseN_1: this.radioGroupN_1,
          AccountNumber: this.account.document.AccountNumber,
          AccountLegalName: this.account.document.AccountLegalName,
          Treated: false,
          Status: 0,
          State: 0,
        })
        .then((response) => {
          request = JSON.parse(response.data);
        });
      this.progressN = 0;
      await this.uploadService
        .UploadChunked(
          request.Id,
          this.fileBaseN,
          "BaseN." + this.fileBaseN.name.split(".").pop().toLowerCase(),
          (_event, _status, pourcentage) => {
            this.progressN = Math.ceil(pourcentage);
          }
        )
        .then(() => { /* TODO document why this arrow function is empty */ })
        .catch(() => {
          this.progressN = -1;
          this.errorUploadedN = true;
          this.message = "Could not upload the file!";
        });
      await this.uploadService
        .GetHeaders(
          request.Id,
          "BaseN." + this.fileBaseN.name.split(".").pop().toLowerCase(),
          this.fileDelimiterBaseN, this.fileEncodingBaseN
        )
        .then(() => {
          this.uploadedN = true;
        })
        .catch(() => {
          this.progressN = -1;
          this.errorUploadedN = true;
          this.message = "Could not upload the file!";
        });
      this.progressN_1 = 0;
      await this.uploadService
        .UploadChunked(
          request.Id,
          this.fileBaseN_1,
          "BaseN_1." + this.fileBaseN_1.name.split(".").pop().toLowerCase(),
          (_event, _status, pourcentage) => {
            this.progressN_1 = Math.ceil(pourcentage);
          }
        )
        .then(() => { /* TODO document why this arrow function is empty */ })
        .catch(() => {
          this.progressN_1 = -1;
          this.errorUploadedN_1 = true;
          this.message = "Could not upload the file!";
        });
      await this.uploadService
        .GetHeaders(
          request.Id,
          "BaseN_1." + this.fileBaseN_1.name.split(".").pop().toLowerCase(),
          this.fileDelimiterBaseN_1, this.fileEncodingBaseN_1
        )
        .then(() => {
          this.uploadedN_1 = true;
        })
        .catch(() => {
          this.progressN_1 = -1;
          this.errorUploadedN_1 = true;
          this.message = "Could not upload the file!";
        });
      this.progressAef = 0;
      await this.uploadService
        .UploadChunked(
          request.Id,
          this.fileMappingAef,
          "MappingAef." + this.fileMappingAef.name.split(".").pop(),
          (_event, _status, pourcentage) => {
            this.progressAef = Math.ceil(pourcentage);
          }
        )
        .then(() => {
          this.uploadedAef = true;
        })
        .catch(() => {
          this.progressAef = -1;
          this.errorUploadedAef = true;
          this.message = "Could not upload the file!";
        });


      if (
        (this.uploadedN == this.uploadedN_1) == this.uploadedAef
      ) {
        await this.requestsService
          .GetRequestsById(request.Id)
          .then((response) => {
            request = JSON.parse(response.data);
          });
        request.State = 1;
        await this.requestsService
          .UpdateRequest(request.Id, request)
          .then(() => {
            this.$emit("NextStep", request);
          });
      }

      this.uploadingFiles = false;
    },
  },
};
</script>
<style lang="scss">
.file-selection-component-class {
  a:hover {
    color: #0056b3;
    text-decoration: none !important;
  }

  .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    font-weight: 600;
  }

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px !important;
  }

  .v-input__control {
    border-radius: 0px !important;
  }

  .v-input__slot {
    background-color: white !important;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-weight: 600;
  }
}
</style>