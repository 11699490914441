const lightTheme = {
    primary: "#00338D",
    secondary: "#005EB8",
    accent: "#483698"
  };
  export const baseProps = {
    props: {
      token: {
        type: String,
        default: () => sessionStorage.getItem("adal.idtoken"),
      },
      language: {
        type: String,
        default: "fr",
      },
    },
    mounted() {
      this.$vuetify.theme.themes.light = lightTheme;
    }
  };
  