<template>
  <v-dialog v-model="open" persistent max-width="290">
    <v-card>
      <v-card-title class="title primary--text font-weight-bold">
        {{ $translate("AefAutomate.Common.Error") }}
      </v-card-title>
      <v-card-text>{{
        $translate("AefAutomate.Common.ErrorMessage")
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 text-none"
          color="red darken-1"
          text
          :to="{ name: 'AefDashboard' }"
        >
          {{ $translate("AefAutomate.Common.ErrorButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
export default {
  name: "ErrorComponent",
  mixins: [baseProps, localizationMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>