import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle mappings
 */
export default class MappingsService extends AbstractService {

    constructor() {
        super();

        this.mappingsClient = super.createClient(configuration.AefAutomateApi.BaseUrl);
    }

    /**
     * Get mappings with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetMappings(search, itemsPerPage, pageNumber, sortBy, sortDesc) {
        return this.mappingsClient.get("v1/Mappings", {
            params: {
                "$top": itemsPerPage,
                "$skip": itemsPerPage * (pageNumber - 1),
                "$filter": `contains(IbsNumber,'${search}') 
                or contains(BusinessUnit,'${search}') 
                or contains(Name,'${search}') 
                or contains(Region,'${search}') 
                or contains(EngagementName,'${search}') 
                or contains(EngagementPartner,'${search}') 
                or contains(TeamContact,'${search}')`,
                "$orderBy": getOrderby(sortBy, sortDesc),
                "$count": true,
                "$expand": 'Team',
            }
        });
    }

    /**
    * Get mappings with filters
    * @param {string} search
    */
    FilterMappings(search) {
        let mappingConfig = {
            params: {
                "$top": 100,
                "$count": true,
                "$expand": 'Team',
            }
        };

        if (search && search !== "") {
            mappingConfig.params["$filter"] = `contains(IbsNumber,'${search}') 
           or contains(BusinessUnit,'${search}') 
           or contains(Name,'${search}') 
           or contains(Region,'${search}') 
           or contains(EngagementName,'${search}') 
           or contains(EngagementPartner,'${search}') 
           or contains(TeamContact,'${search}')`
        }

        return this.mappingsClient.get("Mappings", mappingConfig);
    }

    /**
    * Get mappings by exact name 
    * @param {string} search
    */
    GetMappingsByName(name) {
        let mappingConfig = {
            params: {
                "$top": 10,
                "$count": true,
            }
        };

        if (name && name !== "") {
            mappingConfig.params["$filter"] = `Name eq '${name}'`
        }

        return this.mappingsClient.get("Mappings", mappingConfig);
    }

    /**
     * Delete mapping by id
     * @param {string} id
     */
    DeleteMapping(id) {
        return this.mappingsClient.delete(`v1/Mappings(${id})`)
    }

    /**
     * Updates mapping by id
     * @param {string} id
     * @param {any} mappingBody
     */
    UpdateMapping(id, mappingBody) {
        return this.mappingsClient.patch(`v1/Mappings(${id})`, mappingBody)
    }

    /**
     * Create a mapping
     * @param {any} mappingBody
     */
    CreateMapping(mappingBody) {
        return this.mappingsClient.post("v1/Mappings", mappingBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy && sortBy[0]) {
        orderby = sortBy[0];
        if (sortDesc && sortDesc[0])
            orderby = orderby + " desc"
    }

    return orderby;
}