function getAefAutomatepiBaseUrl() {

    if (process.env.NODE_ENV === "development") {
        return `${window.baseAefAutomate}`;
    }
    else {
        if (window.baseUrl.includes("api.pprod")) {
            return `${window.baseUrl}/pprod/aefautomate`
        }
        
        return `${window.baseUrl}/aefautomate`;
    }
}

export default {
    AefAutomateApi: {
        BaseUrl:
        {
            url: getAefAutomatepiBaseUrl(),
            version: null
        },
    }
}