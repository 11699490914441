import configuration from "../config/configuration";
import AbstractService from "./abstract-service";

/**
 * Service used to handle requests
 */
export default class RequestsService extends AbstractService {

    constructor() {
        super();

        this.requestsClient = super.createClient(configuration.AefAutomateApi.BaseUrl);
    }

    /**
    * Get A Nouveaux
    */
    GetAnouveaux(requestId) {

        return this.requestsClient.get(`v1/Requests/GetUserAnouveaux?requestId=${requestId}`);
    }

    CheckRequestExistanceByAccount(accountNumber) {
        return this.requestsClient.post(`v1/Requests/CheckRequestExistanceByAccount?accountNumber=${accountNumber}`)
    }

    /**
    * Request Status Change
    */
    RequestStatusChange(id) {
        return this.requestsClient.post(`v1/Requests/RequestStatusChange?requestId=${id}`)
    }
    /**
   * get urls reports by RequestId
   */
    GetUrlRapportByRequestId(id) {
        return this.requestsClient.get(`v1/Requests/GetUrlRapportByRequestId?requestId=${id}`)
    }

    /**
     * Get requests with pagination
     * @param {string} search 
     * @param {number} itemsPerPage 
     * @param {number} pageNumber 
     * @param {string} sortBy 
     * @param {bool} sortDesc 
     */
    GetRequests(search, filterStatusItems, itemsPerPage, pageNumber, sortBy, sortDesc) {
        let params = {
            "$top": itemsPerPage,
            "$skip": itemsPerPage * (pageNumber - 1),

            "$orderBy": getOrderby(sortBy, sortDesc),
            "$count": true,
            "$expand": 'Delegations($filter=IsDeleted eq false)'
        }
        let filters = []
        if (search != null && search != '') { filters.push(`contains(AccountLegalName,'${search}')`) }

        if (filterStatusItems != null && filterStatusItems.length != 0) {
            let statusFilter = 'Status in (' + filterStatusItems.join(",") + ')'
            filters.push(statusFilter)
        }
        if (filters.length > 0)
            params["$filter"] = filters.join(" and ")
        return this.requestsClient.get("v1/Requests", {
            params: params
        });
    }

    /**
    * Get requests with filters
    * @param {string} search
    */
    FilterRequests(search) {
        let requestConfig = {
            params: {
                "$top": 100,
                "$count": true,
                "$expand": 'Team',
            }
        };

        if (search && search !== "") {
            requestConfig.params["$filter"] = `contains(IbsNumber,'${search}') 
           or contains(BusinessUnit,'${search}') 
           or contains(Name,'${search}') 
           or contains(Region,'${search}') 
           or contains(EngagementName,'${search}') 
           or contains(EngagementPartner,'${search}') 
           or contains(TeamContact,'${search}')`
        }

        return this.requestsClient.get("Requests", requestConfig);
    }

    /**
    * Get requests by exact name 
    * @param {string} search
    */
    GetRequestsByName(name) {
        let requestConfig = {
            params: {
                "$top": 10,
                "$count": true,
            }
        };

        if (name && name !== "") {
            requestConfig.params["$filter"] = `Name eq '${name}'`
        }

        return this.requestsClient.get("Requests", requestConfig);
    }

    /**
    * Get requests by Id
    * @param {string} id
    */
    GetRequestsById(id) {
        return this.requestsClient.get(`v1/Requests(${id})`)
    }

    /**
    * Get requests by Id
    * @param {string} id
    */
    GenerateData(id) {
        return this.requestsClient.post(`v1/Requests/GenerateData?requestId=${id}`)
    }

    /**
    * Get requests by Id
    * @param {string} id
    */
    UpdateRequestParameters(id, data) {
        return this.requestsClient.post(`v1/Requests/UpdateRequestParameters?requestId=${id}`, data)
    }

    /**
    * Get contact by email 
    * @param {string} email
    */
    GetContactByEmail(email) {
        let params = {
            "$count": true,
        }
        if (email != null && email != '')
            params["$filter"] = `ContactEmailOffice eq '${email}'`
        return this.requestsClient.get("v1/Contacts", {
            params: params
        });
    }

    /**
    * Get contacts 
    * @param {string} search
    * @param {int} itemsPerPage
    */
    GetContacts(search, itemsPerPage) {
        let params = {
            "$top": itemsPerPage,
            "$count": true,
        }
        if (search != null && search != '')
            params["$filter"] = `contains(ContactFullName,'${search}') 
            or contains(ContactEmailOffice,'${search}')`

        return this.requestsClient.get("v1/Contacts", {
            params: params


        });
    }
    /**
    * Get accounts by search string 
    * @param {string} search
    * @param {int} itemsPerPage
    */
    GetAccounts(search) {

        if (search == null) search = ''

        return this.requestsClient.get(`v1/Accounts/GetAccountFromAzureSearch?term=${search}`);
    }

    /**
     * Delete request by id
     * @param {string} id
     */
    DeleteRequest(id) {
        return this.requestsClient.delete(`v1/Requests(${id})`)
    }

    /**
     * Updates request by id
     * @param {string} id
     * @param {any} requestBody
     */
    UpdateRequest(id, requestBody) {
        return this.requestsClient.patch(`v1/Requests(${id})`, requestBody)
    }

    /**
     * Create a request
     * @param {any} requestBody
     */
    CreateRequest(requestBody) {
        return this.requestsClient.post("v1/Requests", requestBody);
    }
}

const getOrderby = (sortBy, sortDesc) => {
    let orderby = "Id";
    if (sortBy && sortBy[0]) {
        orderby = sortBy[0];
        if (sortDesc && sortDesc[0])
            orderby = orderby + " desc"
    }

    return orderby;
}