<template>
  <v-app class="aef-dashboard-view-class">
    <v-container fluid>
      <v-row class="mb-3" no-gutters justify="center" align="center">
        <v-col cols="12" xl="8" lg="6" md="4" sm="12">
          <div class="title primary--text font-weight-bold">
            {{ $translate("AefAutomate.Tdb.Title") }}
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="6" md="8" sm="12">
          <v-row no-gutters justify="center" align="center">
            <v-btn small class="ma-2 text-none" color="primary" :to="{ name: 'AefImport' }">
              <v-icon left dark>mdi-plus-circle-outline</v-icon>
              {{ $translate("AefAutomate.Tdb.GoToGenerateDelivrable") }}
            </v-btn>
            <v-text-field dense v-model="search" :label="$translate('AefAutomate.Tdb.SearchInputLabel')" outlined
              color="primary" hide-details append-icon="mdi-magnify" width="150px" required></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" v-on="on" icon @click="getRequests">
                  <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
              </template>
              {{ $translate("AefAutomate.Tdb.Refresh") }}
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-card class="pa-3" width="100%">
          <v-data-table :headers="headers" :items="requests" :options.sync="options" :server-items-length="totalRequests"
            :loading="loading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" class="data-table-class"
            :footer-props="tableFooterOptions">
            <v-data-footer items-per-page-text="Nombres de lignes par page"></v-data-footer>
            <template v-slot:header.Status="{ header }">
              <span class="white--text">{{ header.text }}</span>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <span class="v-icon mdi mdi-filter theme--light" style="margin-right: 30px; font-size: 18px" v-on="on">
                  </span>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-list dense>
                    <v-list-item-group v-model="filterStatusItems" multiple>
                      <template v-for="item in allStatusItems">
                        <v-list-item :value="item.key" :key="item.key" active-class="primary--text">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title :class="`${item.color}--text`" v-text="item.value"></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox :input-value="active" :true-value="item" :color="item.color"></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-menu>
            </template>
            <template v-slot:item.AccountLegalName="{ item }">
              <v-tooltip bottom v-if="item.Status == 0">
                <template v-slot:activator="{ on }"><span v-on="on" class="d-inline-block text-truncate"
                    style="max-width: 200px; cursor: pointer" @click="handleClickLegal(item)">{{
                      item.AccountLegalName
                    }}</span>
                </template>
                {{ item.AccountLegalName }}
              </v-tooltip>
              <span v-else>{{ item.AccountLegalName }}</span>
            </template>
            <template v-slot:item.CreatedAt="{ item }">
              {{ formatDate(item.CreatedAt) }}
            </template>
            <template v-slot:item.DateClotureN="{ item }">
              {{
                item.Status != 0 && item.Status != 6 && item.Status != 9
                ? formatDate(item.DateClotureN)
                : ""
              }}
            </template>
            <template v-slot:item.Method="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"><span v-on="on" class="d-inline-block text-truncate"
                    style="max-width: 200px; cursor: pointer" @click="handleClick(item, 'KAEG')"> KAEG </span>
                </template>
                KAEG
              </v-tooltip>
            </template>
            <template v-slot:item.edite="{ item }">

              <v-btn v-if="item.Status == 1 || item.Status == 2" icon @click="editSeuilDialog(item)">
                <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.delete="{ item }">
              <v-btn v-if="
                item.Sources != 'DATW' && (item.Status == 1 ||
                  item.Status == 2 ||
                  item.State == 6 ||
                  item.State == 9 ||
                  (item.Status == 0 &&
                    (item.State == 0 || item.State == 1 || item.State == 3)))

              " icon @click="openDeleteDialog(item.Id)">
                <v-icon color="primary">mdi-trash-can-outline</v-icon>
              </v-btn>

            </template>
            <template v-slot:item.CreatedBy="{ item }">
              <span v-if="item.Sources == 'DATW'" style="cursor: pointer;text-decoration: underline;"
                onClick="window.open(`https://digitalwebform.fr.kworld.kpmg.com/`, 'DATW', 'noopener');">
                Digital
                Factory </span>
              <span v-if="item.Sources != 'DATW'">
                {{ item.CreatedBy }} </span>
            </template>
            <template v-slot:item.Status="{ item }">

              <v-row class="mx-3" v-if="item.Status != 1 && item.Status != 2" align="center" no-gutters>
                <v-icon :color="
                  allStatusItems.filter((i) => i.key == item.Status)[0]
                    .color
                " small>mdi-checkbox-blank-circle</v-icon>
                <div class="mx-3">
                  {{
                    allStatusItems.filter((i) => i.key == item.Status)[0]
                      .value
                  }}
                </div>
              </v-row>
              <v-select v-else dense v-model="item.Status" :items="statusItems" @change="requestStatusChange(item.Id)"
                item-value="key" item-text="value" outlined hide-details>
                <template v-slot:selection="data">
                  <!-- HTML that describe how select should render selected items -->
                  <v-row align="center" no-gutters>
                    <v-icon :color="data.item.color" small>mdi-checkbox-blank-circle</v-icon>
                    <div class="mx-3">{{ data.item.value }}</div>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  <v-row align="center" no-gutters>
                    <v-icon :color="data.item.color" small>mdi-checkbox-blank-circle</v-icon>
                    <div class="mx-3">{{ data.item.value }}</div>
                  </v-row>
                </template>
              </v-select>
            </template>
            <template v-slot:item.State="{ item }">
              <v-row align="center" no-gutters>
                <v-icon :color="
                  statesItems.filter((i) => i.key == item.State)[0].color
                " small>mdi-checkbox-blank-circle</v-icon>
                <span class="ml-3">
                  {{ statesItems.filter((i) => i.key == item.State)[0].value }}
                </span>
              </v-row>
            </template>
            <template v-slot:item.Delegations="{ item }">
              <table class="table-children mb-2">
                <caption style="display:none">Delegations</caption>
                <thead style="display:none">
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <template v-for="(delegation, i) of item.Delegations">
                  <tr :key="i" v-if="i < 5">
                    <td class="table-child">
                      {{ delegation.LastName }}, {{ delegation.FirstName }}
                    </td>
                  </tr>
                  <tr v-else-if="i == 5" :key="i">
                    <td class="table-child">.....</td>
                  </tr>
                </template>
                <tr>
                  <td v-if="item.Status == 1" class="table-child">
                    <a class="add-delegation-button-class" @click.stop="showTeamMember(item.Id, item.Sources)"
                      style="color: #00338d; visibility: hidden">
                      <v-icon x-small color="primary" class="mr-2">mdi-pencil-outline</v-icon>
                      {{ $translate("AefAutomate.Tdb.EditDelegation") }}
                    </a>
                  </td>
                </tr>
              </table>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-dialog v-if="dialog" v-model="dialog" persistent width="800">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <div class="title primary--text font-weight-bold">
                    {{ $translate("AefAutomate.EditTeamMembers.Title") }}
                  </div>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <Team-Members-Management-Component :requestId="selectedRequest" :sources="requestSelectedSources">
            </Team-Members-Management-Component>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none" @click="
              dialog = false;
            getRequests();
                                                                    ">
              {{ $translate("AefAutomate.Common.Buttons.Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="dialogDeleteRequest" v-model="dialogDeleteRequest" persistent width="800">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <div class="title primary--text font-weight-bold">
                    {{ $translate("AefAutomate.Tdb.DeleteTitle") }}
                  </div>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            {{ $translate("AefAutomate.Tdb.DeleteText") }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined class="text-none" @click="dialogDeleteRequest = false">
              {{ $translate("AefAutomate.Common.No") }}
            </v-btn>
            <v-btn color="primary" class="text-none" @click="
              deleteRequest(requestIdToDelete);
            dialogDeleteRequest = false;
            getRequests();
                                                                    ">
              {{ $translate("AefAutomate.Common.Yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="dialogEditSeuilRequest" v-model="dialogEditSeuilRequest" persistent width="800">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <div class="title primary--text font-weight-bold">
                    Seuils d’audit (en euros)
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon class="mx-1" v-on="on" color="primary">
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <div style="max-width: 300px">
                        {{
                          $translate(
                            "AefAutomate.Stepper.Settings.Tooltip2"
                          )
                        }}
                      </div>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="Matérialité" rules="required">
                  <v-text-field v-model.lazy="Materialite" hide-details @keypress="onlyNumber" label="Matérialité"
                    outlined :error-messages="errors" append-icon="mdi-currency-eur" required>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="Performance matérialité" rules="required">
                  <v-text-field v-model.lazy="PerfMaterialite" hide-details @keypress="onlyNumber"
                    label="Performance matérialité" outlined :error-messages="errors" append-icon="mdi-currency-eur"
                    required></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="AMPT" rules="required">
                  <v-text-field v-model.lazy="Ampt" hide-details label="AMPT" outlined @keypress="onlyNumber"
                    :error-messages="errors" append-icon="mdi-currency-eur" required>
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined class="text-none" @click="dialogEditSeuilRequest = false">
              {{ $translate("AefAutomate.Common.Buttons.Cancel") }}
            </v-btn>
            <v-btn :loading="editRequestSeuilLoading" color="primary" class="text-none" @click="
              updateRequestSeuil(requestIdToDelete);
                                                                    ">
              {{ $translate("AefAutomate.Common.Buttons.Confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import { baseProps } from "@mixins/baseComponent";
import { localizationMixin } from "@mixins/localization";
import { utilsMixin } from "@mixins/utils";
import RequestsService from "@services/requests-service";
import TeamMembersManagementComponent from "./TeamMembersManagementComponent";

export default {
  name: "AefDashboard",
  mixins: [baseProps, localizationMixin, utilsMixin],
  components: {
    TeamMembersManagementComponent,
  },
  data() {
    return {
      requestsService: null,
      sortBy: "CreatedAt",
      sortDesc: true,
      statusItems: [
        { value: "Actif", key: 1, color: "green" },
        { value: "Inactif", key: 2, color: "grey" },
      ],
      filterStatusItems: [0, 1, 2],
      allStatusItems: [
        { value: "En cours", key: 0, color: "blue", active: true },
        { value: "Actif", key: 1, color: "green", active: true },
        { value: "Inactif", key: 2, color: "grey", active: true },
        { value: "Remplacé", key: 3, color: "orange", active: false },
        { value: "Supprimé", key: 4, color: "red", active: false },
      ],
      statesItems: [
        { value: "Initialisation", key: 0, color: "yellow" },
        { value: "Fichers chargés", key: 1, color: "yellow" },
        { value: "Lecture des fichiers en cours", key: 2, color: "yellow" },
        { value: "Attente de parametrage", key: 3, color: "yellow" },
        { value: "Process cube en cours", key: 4, color: "yellow" },
        { value: "En attente de process (Queued)", key: 8, color: "yellow" },
        { value: "Process parallèl en cours", key: 13, color: "yellow" },
        { value: "Terminé", key: 5, color: "green" },
        { value: "Erreur", key: 6, color: "red" },
        { value: "Erreur", key: 9, color: "red" },
        { value: "Suppression en cours", key: 10, color: "red" },
        { value: "Mise à jour des seuils en cours", key: 11, color: "yellow" }

      ],
      itemsPerPage: 10,
      tableFooterOptions: {},
      search: null,
      requests: [],
      totalRequests: 0,
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Méthodologie",
          align: "start",
          width: "150",
          value: "Method",
        },
        {
          text: "Nom du client",
          sortable: true,
          width: "150",
          value: "AccountLegalName",
        },
        { text: "Création le", value: "CreatedAt" },
        { text: "Clôture du", value: "DateClotureN" },
        { text: "Chargé par", value: "CreatedBy" },
        { text: "Équipe", value: "Delegations" },
        { text: "Statut", value: "Status", width: "200" },
        { text: "État", value: "State", width: "260" },
        { text: "Seuils", value: "edite", sortable: false },
        { text: "Suppression", value: "delete", sortable: false },

      ],
      selectedRequest: null,
      dialog: false,
      dialogDeleteRequest: false,
      requestIdToDelete: null,
      dialogEditSeuilRequest: false,
      requestSelectedSources: "AEF",
      requestToEditSeuil: null,
      editRequestSeuilLoading: false,
      AefAutomateReport: window.AefAutomateReport,
      Materialite: 0,
      PerfMaterialite: 0,
      Ampt: 0,
    };
  },
  computed: {
    userEmail() {
      let token = sessionStorage.getItem("adal.idtoken");
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload).email;
    },
  },
  watch: {
    filterStatusItems() {
      this.getRequests();
    },
    options() {
      this.getRequests();
    },
    search(val) {
      if (!val || val.length >= 3) {
        this.getRequests();
      }
    },
    Materialite() {
      this.changeFormatMaterialite();
    },
    Ampt() {
      this.changeFormatAmpt();
    },
    PerfMaterialite() {

      this.changeFormatPerfMaterialite();
    }
  },
  async created() {
    this.requestsService = new RequestsService();

   


  },
  mounted() {
    this.tableFooterOptions = {
      "disable-items-per-page": true,
      "items-per-page-options": [this.itemsPerPage],
    };
    this.getRequests();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    changeFormatPerfMaterialite() {
      const numberFormat = new Intl.NumberFormat();
      this.PerfMaterialite = numberFormat.format(
        this.PerfMaterialite.replace(/\s/g, "")
      )

    },
    changeFormatAmpt() {
      const numberFormat = new Intl.NumberFormat();
      this.Ampt = numberFormat.format(
        this.Ampt.replace(/\s/g, "")
      )
    },
    changeFormatMaterialite() {
      const numberFormat = new Intl.NumberFormat();
      this.Materialite = numberFormat.format(
        this.Materialite.replace(/\s/g, "")
      )
    },
    async updateRequestSeuil() {
      this.editRequestSeuilLoading = true;
      let data = {
        Materialite: parseFloat(this.Materialite.toString().replace(/\s/g, "")),
        PerfMaterialite: parseFloat(this.PerfMaterialite.toString().replace(/\s/g, "")),
        Ampt: parseFloat(this.Ampt.toString().replace(/\s/g, ""))
      }
      await this.requestsService.UpdateRequestParameters(this.requestToEditSeuil.Id, data).then(() => { /* TODO document why this arrow function is empty */ });
      this.editRequestSeuilLoading = false;
      this.dialogEditSeuilRequest = false;
      this.getRequests();
    },
    editSeuilDialog(request) {

      this.requestToEditSeuil = JSON.parse(JSON.stringify(request));
      this.Materialite = this.requestToEditSeuil.Materialite;
      this.PerfMaterialite = this.requestToEditSeuil.PerfMaterialite;
      this.Ampt = this.requestToEditSeuil.Ampt;
      // change format to format money
      const numberFormat = new Intl.NumberFormat();
      this.Materialite = numberFormat.format(
        this.Materialite
      )
      this.PerfMaterialite = numberFormat.format(
        this.PerfMaterialite
      );
      this.Ampt = numberFormat.format(
        this.Ampt
      );

      this.dialogEditSeuilRequest = true;
    },
    openDeleteDialog(requestId) {
      this.requestIdToDelete = requestId;
      this.dialogDeleteRequest = true;
    },
    async deleteRequest(requestId) {
      await this.requestsService.DeleteRequest(requestId).then(() => {
        this.requestIdToDelete = null;
        this.getRequests();
      });
    },
    async requestStatusChange(requestId) {
      await this.requestsService.RequestStatusChange(requestId).then(() => {
        this.getRequests();
      });
    },
    showTeamMember(requestId, sources) {
      this.selectedRequest = requestId;
      this.dialog = true;
      this.requestSelectedSources = sources;
    },
    async handleClick(request, type) {
        if (request.Status == 0 && request) {
        this.$router.push({
            name: "AefImport",
            query: { requestId: request.Id },
        });
        }
        if (request.Status != 1) {
        return;
        }
        let url = null;
        url = JSON.parse((await this.requestsService.GetUrlRapportByRequestId(request.Id)).data);
        if (url == null) { return; }
        
        if (type == "KAEG") {

        window.open(
            url?.kaeg 
        );

        
        }




    },
    handleClickLegal(request) {
      if (request.Status == 0 && request)
        this.$router.push({
          name: "AefImport",
          query: { requestId: request.Id },
        });
    },
    getRequests() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loading = true;
      this.requestsService
        .GetRequests(
          this.search,
          this.filterStatusItems,
          itemsPerPage,
          page,
          sortBy,
          sortDesc
        )
        .then((res) => {
          let requestList = res.data.values;
          this.requests = requestList;
          this.totalRequests = res.data.count;
        })
        .catch((ex) => {
          console.log(ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.aef-dashboard-view-class {
  height: 100%;
  background-color: #e6ebf4 !important;

  a:hover {
    text-decoration: none !important;
  }

  .add-delegation-button-class {
    font-size: 0.75rem;
    font-weight: 600;
    color: #00338d;
  }

  .column {
    max-width: 100%;
  }

  .table-children tr {
    border: 0px !important;
  }

  .table-child {
    border: none !important;
    height: inherit !important;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    padding: 5px 0px !important;
  }

  .v-data-table tbody tr:hover table .table-child button,
  .v-data-table tbody tr:hover a,
  .v-data-table tbody tr:hover .btn-action {
    display: block !important;
    visibility: visible !important;
  }

  .v-text-field--outlined,
  .v-text-field--solo {
    border-radius: 0px;
  }

  .v-input__slot {
    background-color: white !important;
  }

  .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
  }

  .data-table-class {
    thead {
      background-color: #00338d;

      th {
        border: thin solid white;
        position: relative;

        span {
          color: white;
        }

        i {
          position: absolute;
          right: 5px;
          color: white !important;
        }
      }
    }

    tbody {
      td {
        color: #00338d;
        border: thin solid rgba(0, 0, 0, 0.12);
      }
    }

    .v-data-footer {
      .v-data-footer__select {
        color: #00338d;
      }

      .v-select__selections {
        color: #00338d;
      }

      .v-data-footer__pagination {
        color: #00338d;
      }

      .v-data-footer__icons-before {
        i {
          color: #00338d;
        }
      }

      .v-data-footer__icons-after {
        i {
          color: #00338d;
        }
      }
    }
  }
}
</style>
