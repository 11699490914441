import AefDashboard from "@/components/dashboard/AefDashboard";
import AefImport from "@/components/import/AefImport";
import AefViewReport from "@/components/dashboard/AefViewReport";
import AefFaq from "@/components/AefFaq";

export default {
    AefDashboard,
    AefImport,
    AefViewReport,
    AefFaq
};



window.version = window.version || []

window.version['aef-automate'] = "1.1.0.0"